import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './CustomStyles.css';
import { usePromptTable } from "context";
import { useQueriesTable } from "context";
import { MenuItem, Box, Typography, IconButton, Menu } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import CustomDrawer from "./CustomDrawer";
import AgentQueryDropDown from "./AgentQueryDropDown";

const PromptsQuerySelectors = ({
  onQueryUpdate,
  filterState,
  setFilterState,
  handleSort,
  sortCriteria,
  sortOrder,
  sortingOptionList
}) => {
  const { rowsPromptTable, fetchPrompts } = usePromptTable();
  const { queriesTableRows, fetchQueries } = useQueriesTable();
  const [options, setOptions] = useState([]);
  const [optionsQuery, setOptionsQuery] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState({ value: '', prompt: '', label: '' });
  const [selectedQuery, setSelectedQuery] = useState({ value: '', label: '' });
  const [openMenu, setOpenMenu] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const toggleDrawer = (newOpen) => () => setOpen(newOpen);

  useEffect(() => {
    fetchPrompts();
  }, []);

  // Generate options for agents and set the first prompt in the list of options
  useEffect(() => {
    if (rowsPromptTable.length > 0) {
      const opt = rowsPromptTable.map((value) => ({
        value: value.promptID,
        label: value.promptTitle,
        prompt: value.prompt,
      }));
      setOptions(opt);
      if (opt.length > 0) {
        setSelectedPrompt({
          value: opt[0].value,
          prompt: opt[0].prompt,
          label: opt[0].promptTitle,
        });
      }
    }
  }, [rowsPromptTable]);

  // When a prompt is selected, fetch all the queries associated with that prompt
  useEffect(() => {
    if (selectedPrompt.value !== "") {
      fetchQueries(selectedPrompt.value);
    }
  }, [selectedPrompt]);

  // If a new set of queries is available, update options and fetch data from the feed table with onQueryUpdate
  useEffect(() => {
    if (queriesTableRows.length > 0) {
      const opt = queriesTableRows.map((value) => ({
        value: value.queryID,
        label: value.query,
      }));
      setOptionsQuery(opt);
      if (opt.length > 0) {
        setSelectedQuery({ value: opt[0].value, label: opt[0].label });
        onQueryUpdate(opt[0].value);
      }
    }
  }, [queriesTableRows]);

  const handleChangePrompt = (event) => {
    const selectedValue = event.target.value;
    const newPrompt = options.find(option => option.value === selectedValue);
    if (newPrompt) {
      setSelectedPrompt({
        value: newPrompt.value,
        prompt: newPrompt.prompt,
        label: newPrompt.label,
      });
    } else {
      console.error("Prompt not found");
    }
  };

  const handleChangeQuery = (event) => {
    const selectedValue = event.target.value;
    const newQuery = optionsQuery.find(option => option.value === selectedValue);
    if (newQuery) {
      setSelectedQuery({ value: newQuery.value, label: newQuery.label });
      onQueryUpdate(newQuery.value);
    } else {
      console.error("Query not found");
    }
  };

  const [openInfoPrompt, setOpenInfoPrompt] = useState(false);
  const handleOpenInfoPrompt = () => setOpenInfoPrompt(true);
  const handleCloseInfoPrompt = () => setOpenInfoPrompt(false);

  const [openInfoQuery, setOpenInfoQuery] = useState(false);
  const handleOpenInfoQuery = () => setOpenInfoQuery(true);
  const handleCloseInfoQuery = () => setOpenInfoQuery(false);

  const logoPath = JSON.parse(localStorage.getItem('userProfile'))?.logo;
  return (
    <>
      <MDBox
        mx={2}
        mt={12}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" },
            gap: "5px",
          }}
        >
          {logoPath ? (
            <Box sx={{ flex: '0 1 10%', display: 'flex', justifyContent: 'flex-start' }}>
              <img src={logoPath} alt="Logo" style={{ maxHeight: '100px', maxWidth: '100%', padding: "5px" }} />
            </Box>
          ) : null}


          {/* Agent Type Dropdown */}
          <AgentQueryDropDown
            title="Agent Type"
            labelId="dropdown-prompt-label"
            value={selectedPrompt.value}
            onChange={handleChangePrompt}
            options={options}
            openInfo={openInfoPrompt}
            handleOpenInfo={handleOpenInfoPrompt}
            handleCloseInfo={handleCloseInfoPrompt}
            infoTitle="Agent Type"
            infoContent={selectedPrompt.prompt}
          />

          {/* Area of Search Dropdown */}
          <AgentQueryDropDown
            title="Area of Search"
            labelId="dropdown-query-label"
            value={selectedQuery.value}
            onChange={handleChangeQuery}
            options={optionsQuery}
            openInfo={openInfoQuery}
            handleOpenInfo={handleOpenInfoQuery}
            handleCloseInfo={handleCloseInfoQuery}
            infoTitle="Area of Search"
            infoContent={selectedQuery.label}
          />

          <Box sx={{ width: "5%", display: "flex", justifyContent: "flex-end", paddingRight: "30px" }}>
            {/* Render Filter Button and Drawer */}
            {filterState && setFilterState && (
              <>
                <Button
                  startIcon={<FilterAltIcon sx={{ color: "#fff" }} />}
                  onClick={toggleDrawer(true)}
                  sx={{
                    color: "#fff !important",
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#fff !important",
                    },
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Filter
                </Button>
                <Divider
                  orientation="vertical"
                  sx={{ border: "1px solid #fff !important", height: "auto" }}
                />
              </>
            )}

            {/* Render Sort By Button */}
            {handleSort && sortCriteria && sortOrder && sortingOptionList && (
              <>
                {/* Open Sorting Menu */}
                <Button
                  onClick={handleOpenMenu}
                  sx={{
                    color: "#fff !important",
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#fff !important",
                    },
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Sort by
                </Button>
                <Menu
                  anchorEl={openMenu}
                  anchorReference={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  open={Boolean(openMenu)}
                  onClose={handleCloseMenu}
                  sx={{ mt: 2 }}
                >
                  {sortingOptionList.map((option) => (
                    <MenuItem key={option.criteria} onClick={() => handleSort(option.criteria)}>
                      <IconButton onClick={() => handleSort(option.criteria)}>
                        <Typography variant="body2" sx={{ mr: 1 }}>
                          {option.label}
                        </Typography>
                        {sortCriteria === option.criteria &&
                          (sortOrder === "asc" ? (
                            <SortIcon sx={{ transform: "rotate(180deg)" }} />
                          ) : (
                            <SortIcon />
                          ))}
                      </IconButton>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Box>

          {/* Render Drawer */}
          {filterState && setFilterState && (
            <CustomDrawer
              open={open}
              setOpen={setOpen}
              toggleDrawer={toggleDrawer}
              filterState={filterState}
              setFilterState={setFilterState}
            />
          )}
        </Box>
      </MDBox>
    </>
  );
};

export default PromptsQuerySelectors;
