import React from 'react';
import { Box, Typography, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoDialogMarkdown from './InfoDialogMarkdown'; // Assuming you have this component

const AgentQueryDropDown = ({
  title,
  labelId,
  value,
  onChange,
  options,
  openInfo,
  handleOpenInfo,
  handleCloseInfo,
  infoTitle,
  infoContent,
}) => {
  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', paddingLeft: '45px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
        <Typography variant="h6" sx={{ color: "#fff", marginRight: 1 }}>
          {title}
        </Typography>
        <IconButton onClick={handleOpenInfo}>
          <HelpOutlineIcon color="white" />
        </IconButton>
      </Box>
      <FormControl fullWidth>
        <InputLabel
          id={labelId}
          sx={{ color: "#fff !important", display: "none" }}
        >
          Select {title}
        </InputLabel>
        <Select
          labelId={labelId}
          value={value}
          onChange={onChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 600,
                maxWidth: 500,
                backgroundColor: "white",
                color: "black",
              },
            },
          }}
          className="select-dropdown"
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ color: 'black', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {option.label.split(' ').length > 5 ? `${option.label.split(' ').slice(0, 5).join(' ')} ...` : option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <InfoDialogMarkdown
        open={openInfo}
        onClose={handleCloseInfo}
        title={infoTitle}
        content={infoContent}
      />
    </Box>
  );
};

export default AgentQueryDropDown;
