import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

function preprocessMarkdown(text) {
  return text
    .replace(/\n{3,}/g, '\n\n') // Limit excessive newlines to two max
    .replace(/ {2,}/g, ' ') // Replace multiple spaces with a single space
    .trim(); // Trim any extra spaces or newlines at the start or end
};

const InfoDialogMarkdown = ({ open, onClose, title, content }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ padding: '1rem', maxWidth: '100%', overflowX: 'hidden' }}>
        <Box
          sx={{
            backgroundColor: '#000',
            padding: '1rem',
            borderRadius: '8px',
            maxWidth: '100%',
            overflowX: 'hidden',
            whiteSpace: 'pre-line',
            boxSizing: 'border-box',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: 'white',
              fontSize: '0.875rem',
              lineHeight: '1.5',
              wordBreak: 'normal',
              whiteSpace: 'pre-line',
            }}
          >
            <span style={{ color: 'white' }}>
              <ReactMarkdown>{preprocessMarkdown(content)}</ReactMarkdown>
            </span>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialogMarkdown;
