/**
 * =========================================================
 * Kilner Tech Dashboard
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)
 *
 * Coded by www.creative-tim.com
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Kilner Tech Dashboard React Context Providers
import { MaterialUIControllerProvider, UserProvider } from "context";
import {PromptTableProvider, QueriesTableProvider} from "context";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <UserProvider>
      <PromptTableProvider>
        <QueriesTableProvider>
          <MaterialUIControllerProvider>{/* Remove unnecessary line breaks here */}
            <App />
          </MaterialUIControllerProvider>
          </QueriesTableProvider>
      </PromptTableProvider>
    </UserProvider>
  </BrowserRouter>
);
