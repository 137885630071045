import React, { useState, useEffect, useRef } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Box, Pagination } from "@mui/material";
import PromptsQuerySelectors from "customizedComponents/PromptQuerySelectors";
import FeedItem from "./FeedItem";
import { useNavigate } from "react-router-dom";
import { buildURL, rootAPI, callAPI } from "api/callAPI";


const Feed = () => {
  const [loading, setLoading] = useState(false);
  const cache = useRef({});
  const [feedData, setFeedData] = useState([]);
  const [filteredDataState, setFilteredDataState] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const sortingOptionList = [
    { criteria: "date", label: "Date" },
    { criteria: "levelOfConcern", label: "Level of Concern" },
    { criteria: "relevanceScore", label: "Relevance" },
  ];
  const [filterState, setFilterState] = useState(() => {
    const savedFilters = localStorage.getItem("feedFilters");
    return {
          filters:  JSON.parse(savedFilters) || {
            startDate: "",
            endDate: "",
            termsActivity: [],
            name: [],
            country: [],
            party: [],
            group: [],
            typeDocument: [],
            levelOfConcern: [0, 100],
            relevanceScore: [0.33, 1],
          },
          metadata: {
            startDate: { type: "date", description: "Start Date" },
            endDate: { type: "date", description: "End Date" },
            termsActivity: { type: "autocomplete", description: "Term" },
            name: { type: "autocomplete", description: "Member of Parliament" },
            country: { type: "autocomplete", description: "Country" },
            party: { type: "autocomplete", description: "Party" },
            group: { type: "autocomplete", description: "Group" },
            typeDocument: { type: "autocomplete", description: "Document Type" },
            levelOfConcern: {
              type: "slider",
              description: "Level of Concern",
              min: 0,
              max: 100,
            },
            relevanceScore: {
              type: "slider",
              description: "Relevance Score",
              min: 0.33,
              max: 1,
              step: 0.01,
            },
          },
          options: {
            termsActivity: ["Past MP", "Current Sitting MPs"],
            name: [],
            country: [],
            party: [],
            group: [],
            typeDocument: [],
          },
        };
  });

  const fetchFeed = async (queryID) => {
    setLoading(true);
    let apiData;

    if (!cache.current[queryID]) {
      try {
        const data = await callAPI(
          buildURL(rootAPI, `user/feed?queryID=${queryID}`),
          "GET",
          null,
          navigate
        );
        apiData = await data.response;
        cache.current[queryID] = apiData;
      } catch (error) {
        console.error("Error fetching data:", error);
        apiData = [];
      } finally {
        setLoading(false);
      }
    } else {
      apiData = cache.current[queryID];
      setLoading(false);
    }

    setFeedData(apiData);

    // Extract options from API data
    const extractOptions = (key) =>
      [...new Set(apiData.map((item) => item[key]))]
        .filter(Boolean)
        .sort();

    setFilterState((prev) => ({
      ...prev,
      options: {
        termsActivity: ["Past MEPs","Currently Sitting MEPs"],
        name: extractOptions("name"),
        country: extractOptions("country"),
        party: extractOptions("party"),
        group: extractOptions("group"),
        typeDocument: extractOptions("typeDocument"),
      },
    }));
  };

  const applyFilters = () => {
    let data = [...feedData];
    const { filters, metadata } = filterState;

    Object.entries(filters).forEach(([key, value]) => {
      if (!value || (Array.isArray(value) && value.length === 0)) return;

      switch (metadata[key]?.type) {
        case "date":
          const date = new Date(value || "");
          data = data.filter((item) => {
            const itemDate = new Date(item.date || "");
            return (
              (key === "startDate" && itemDate >= date) ||
              (key === "endDate" && itemDate <= date)
            );
          });
          break;

          case "autocomplete":
            if (key === "termsActivity") {
              // Specific logic for termsActivity
              data = data.filter((item) => {
                if (value.includes("Past MEPs")) {
                  return item[key]?.length === 1 && item[key]?.includes(9);
                }
                if (value.includes("Currently Sitting MEPs")) {
                  return item[key]?.includes(10);
                }
                return false; // Fallback case if no valid termsActivity option is selected
              });
            } else {
              // Generic autocomplete logic for other filters
              data = data.filter((item) =>
                value.some((val) => item[key]?.toLowerCase().includes(val.toLowerCase()))
              );
            }
            break;

        case "slider":
          const [min, max] = value;
          data = data.filter(
            (item) => (item[key] || 0) >= min && (item[key] || 0) <= max
          );
          break;

        default:
          break;
      }
    });

    // Sorting logic
    switch (sortCriteria) {
      case "date":
        data.sort((a, b) =>
          sortOrder === "asc"
            ? new Date(a.date || "") - new Date(b.date || "")
            : new Date(b.date || "") - new Date(a.date || "")
        );
        break;
      case "levelOfConcern":
        data.sort((a, b) =>
          sortOrder === "asc"
            ? (a.levelOfConcern || 0) - (b.levelOfConcern || 0)
            : (b.levelOfConcern || 0) - (a.levelOfConcern || 0)
        );
        break;
      case "relevanceScore":
        data.sort((a, b) =>
          sortOrder === "asc"
            ? (a.relevanceScore || 0) - (b.relevanceScore || 0)
            : (b.relevanceScore || 0) - (a.relevanceScore || 0)
        );
        break;
      default:
        break;
        
    }
    setFilteredDataState(data);
    // Pagination
    const startIndex = (currentPage - 1) * itemsPerPage;
    setDisplayedData(data.slice(startIndex, startIndex + itemsPerPage));
  };


  useEffect(() => {
    applyFilters();
    // update local storage
    console.log(filterState.filters);
    localStorage.setItem("feedFilters", JSON.stringify(filterState.filters));

  }, [feedData, filterState, sortCriteria, sortOrder, currentPage]);

  const handleSort = (criteria) => {
    if (sortCriteria === criteria) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortCriteria(criteria);
      setSortOrder("asc");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PromptsQuerySelectors
        onQueryUpdate={fetchFeed}
        filterState={filterState}
        setFilterState={setFilterState}
        handleSort={handleSort}
        sortCriteria={sortCriteria}
        sortOrder={sortOrder}
        sortingOptionList={sortingOptionList}
      />
      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <FeedItem loading={loading} data={displayedData} />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(filteredDataState.length / itemsPerPage)}
            page={currentPage}
            onChange={(e, page) => setCurrentPage(page)}
          />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Feed;
