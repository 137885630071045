import React from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDBox from "components/MDBox";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";

const MPInfoProfile = (props) => {
  const { interventions } = props;

  return (
    <MDBox width="100%">
      <Grid item xs={20} md={4}>
        <Card>
          <MDBox p={2}>
            {/* Header */}

            {/* Tabs */}

            {/* Sorting */}
            <Grid container spacing={2}>
              {/* Accordion */}
              <Grid item xs={12}>
                {interventions.length > 0 ? (
                  interventions.map((item, index) => (
                    <Accordion key={index}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box
                          sx={{
                            flexDirection: { xs: "column", lg: "row" },
                            gap: { xs: "15px", sm: "0px" },
                          }}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Box flexGrow={1}>
                            <MDTypography
                              variant="body2"
                              fontWeight="bold"
                              style={{ fontSize: 14, textAlign: "left" }}
                            >
                              {item.title}
                            </MDTypography>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{
                              minWidth: "150px",
                              justifyContent: "flex-end",
                            }}
                          >
                            <MDTypography
                              variant="body2"
                              color="textSecondary"
                              style={{ marginRight: 8, fontSize: 12 }}
                            >
                              {item.date}
                            </MDTypography>
                            <MDTypography
                              variant="body2"
                              color="textSecondary"
                              style={{ fontSize: 12 }}
                            >
                              LoC: {item.levelOfConcern.toFixed(1)}
                            </MDTypography>
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MDTypography
                          variant="body2"
                          fontWeight="bold"
                          style={{ textAlign: "left" }}
                        >
                          Summary:
                        </MDTypography>
                        <MDTypography
                          variant="body2"
                          fontWeight="italic"
                          style={{
                            textAlign: "left",
                            marginBottom: 18,
                            marginTop: 8,
                          }}
                        >
                          {item.summary}
                        </MDTypography>
                        {item.url && (
                          <MDTypography
                            variant="body2"
                            fontWeight="italic"
                            color="primary"
                          >
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Intervention Source
                            </a>
                          </MDTypography>
                        )}
                        <MDTypography
                          variant="body2"
                          fontWeight="bold"
                          style={{ textAlign: "left", marginTop: 18 }}
                        >
                          Alignment:
                        </MDTypography>
                        <MDTypography variant="body2" style={{ marginTop: 8 }}>
                          {item.response}
                        </MDTypography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Card>
                    <CardContent sx={{ textAlign: "center", pt: 3 }}>
                      No Data Found
                    </CardContent>
                  </Card>
                )}
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </Grid>
    </MDBox>
  );
};

export default MPInfoProfile;
