import React, { useEffect, useState } from "react";
import {
  Avatar,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Link,
  Divider,
  Typography,
  Menu,
  Button,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SortIcon from "@mui/icons-material/Sort";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import MDBox from "components/MDBox";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";

const MPInfo = (props) => {
  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  const { bioData, interventions } = props;
  const [sortCriteria, setSortCriteria] = useState("date");
  const [sortOrder, setSortOrder] = useState("asc");
  const [openMenu, setOpenMenu] = useState(false);
  const [combinedArray, setCombinedArray] = useState([]);

  useEffect(() => {
    const mycombinedArray = [
      ...interventions.explanationsOfVote,
      ...interventions.debates,
      ...interventions.questions,
    ];
    mycombinedArray.sort((a, b) => new Date(b.date) - new Date(a.date));
    setCombinedArray(mycombinedArray);
  }, [interventions]);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleSort = (criteria) => {
    if (sortCriteria === criteria) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortCriteria(criteria);
      setSortOrder("asc");
    }
  };

  const sortedData = [...combinedArray].sort((a, b) => {
    if (sortCriteria === "date") {
      return sortOrder === "asc"
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    } else if (sortCriteria === 'levelOfConcern') {
      return sortOrder === "asc" ? a.levelOfConcern - b.levelOfConcern : b.levelOfConcern - a.levelOfConcern;
    }
  });


  const titleCaseName = toTitleCase(bioData.name);
  console.log("bioData", bioData);

  return (
    <MDBox pt={3} width="100%">
      <Grid item xs={20} md={4}>
        <Card sx={{ width: "100% !important" }}>
          <Box sx={{ width: "100% !important", p: 3 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center !important",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box>
                  {bioData.imgLink ? (
                    <Link
                      href={bioData.homePage}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Avatar
                        alt={titleCaseName}
                        src={bioData.imgLink}
                        style={{ width: 160, height: 160 }}
                      />
                    </Link>
                  ) : (
                    <Avatar style={{ width: 80, height: 80 }}>
                      {titleCaseName.slice(0, 2).toUpperCase()}
                    </Avatar>
                  )}
                </Box>
                <Box sx={{}}>
                  {" "}
                  <Link
                    href={bioData.homePage}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                  >
                    <Typography variant="h5" component="div" fontWeight="bold">
                      {titleCaseName}
                    </Typography>
                  </Link>
                  <MDTypography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                  >
                    {bioData.country}
                  </MDTypography>
                  {bioData.sitting && (
                    <MDTypography
                      sx={{ color: "grey" }}
                      style={{ whiteSpace: "nowrap" }} // Ensures the text stays on the same line
                      fontWeight="italic"
                      fontStyle="italic"
                      fontSize="12px"
                    >
                      ({bioData.sitting})
                    </MDTypography>
                  )}
                </Box>
              </Box>
              <Divider
                orientation="vertical"
                sx={{
                  borderColor: "black",
                  height: "auto",
                  alignSelf: "stretch",
                  mr: 4,
                }}
              />
              <Box>
                <ul>
                  <li>
                    <MDTypography
                      variant="body2"
                      color="textSecondary"
                      component="div"
                    >
                      {bioData.group}
                    </MDTypography>
                  </li>
                  <li>
                    <MDTypography
                      variant="body2"
                      color="textSecondary"
                      component="div"
                      // style={{ marginTop: 12 }}
                    >
                      {bioData.party}
                    </MDTypography>
                  </li>
                  <li>
                    <MDTypography
                      variant="body2"
                      color="textSecondary"
                      component="div"
                      // style={{ marginTop: 12 }}
                    >
                      {bioData.country}
                    </MDTypography>
                  </li>
                </ul>
              </Box>

              <Divider
                orientation="vertical"
                sx={{
                  borderColor: "black",
                  height: "auto",
                  alignSelf: "stretch",
                }}
              />
              <Box>
                <MDTypography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  style={{ marginTop: 12 }}
                >
                  Average Level Of Concern:{" "}
                  {Math.round(bioData.levelOfConcern * 10) / 10}
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  style={{ marginTop: 12 }}
                >
                  Total Interventions: {bioData.totalInterventions}
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  style={{ marginTop: 12 }}
                >
                  Impact Score: {bioData.impactScore}
                </MDTypography>
              </Box>

              <Divider
                orientation="vertical"
                sx={{
                  borderColor: "black",
                  height: "auto",
                  alignSelf: "stretch",
                }}
              />
              <Box sx={{ display: "flex", gap: "1.5rem" }}>
                {bioData.email && (
                  <Link
                    href={`mailto:${bioData.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EmailIcon sx={{ color: "#757575" }} />
                  </Link>
                )}
                {bioData.instagram && (
                  <Link
                    href={bioData.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon sx={{ color: "#757575" }} />
                  </Link>
                )}
                {bioData.facebook && (
                  <Link
                    href={bioData.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon sx={{ color: "#757575" }} />
                  </Link>
                )}
                {bioData.twitter && (
                  <Link
                    href={bioData.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon sx={{ color: "#757575" }} />
                  </Link>
                )}
                {bioData.homePage && (
                  <Link
                    href={bioData.homePage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LanguageIcon sx={{ color: "#757575" }} />
                  </Link>
                )}
              </Box>
            </Box>

            {/* Tabs */}

            <Box
              sx={{
                mt: 4,
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "end",
                alignItems: "center",
                background: "#F8F9FA",
                p: 1,
                borderRadius: "0.8rem",
              }}
            >
              <Box>
                <Button
                  onClick={handleOpenMenu}
                  sx={{
                    width: "100%",
                    pr: 2,
                    pl: 2,
                    color: "#08344C", // Set text color to #08344C
                    backgroundColor: "transparent", // Keep background transparent
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#08344C", // Keep background transparent on hover
                    },
                  }}
                >
                  Sort by
                </Button>
                <Menu
                  anchorEl={openMenu}
                  anchorReference={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  open={Boolean(openMenu)}
                  onClose={handleCloseMenu}
                  sx={{ mt: 2 }}
                >
                  <MenuItem>
                    <IconButton onClick={() => handleSort("date")}>
                      <MDTypography variant="body2" style={{ marginLeft: 4 }}>
                        Date
                      </MDTypography>
                      {sortCriteria === "date" &&
                        (sortOrder === "asc" ? (
                          <SortIcon
                            style={{
                              transform: "rotate(180deg)",
                              marginLeft: 4,
                            }}
                          />
                        ) : (
                          <SortIcon style={{ marginLeft: 4 }} />
                        ))}
                    </IconButton>
                  </MenuItem>
                  <MenuItem>
                    <IconButton onClick={() => handleSort("levelOfConcern")}>
                      <MDTypography variant="body2" style={{ marginLeft: 4 }}>
                        Level Of Concern
                      </MDTypography>
                      {sortCriteria === "levelOfConcern" &&
                        (sortOrder === "asc" ? (
                          <SortIcon
                            style={{
                              transform: "rotate(180deg)",
                              marginLeft: 4,
                            }}
                          />
                        ) : (
                          <SortIcon style={{ marginLeft: 4 }} />
                        ))}
                    </IconButton>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>

            {/* Header */}
            {/* Sorting */}
            <Grid container spacing={2} paddingTop={3}>
              {/* Accordion */}
              <Grid item xs={12}>
                {sortedData.map((item, index) => (
                  <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box
                        sx={{
                          flexDirection: { xs: "column", sm: "row" },
                          gap: { xs: "15px", sm: "0px" },
                        }}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        <Box flexGrow={1}>
                          <MDTypography
                            variant="body2"
                            fontWeight="bold"
                            style={{ fontSize: 18, textAlign: "left" }}
                          >
                            {item.title}
                          </MDTypography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{
                            minWidth: "150px",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MDTypography
                            variant="body2"
                            color="textSecondary"
                            style={{ marginRight: 8, fontSize: 12 }}
                          >
                            {item.date}
                          </MDTypography>
                          <MDTypography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontSize: 12 }}
                          >
                            LoC: {item.levelOfConcern.toFixed(1)}
                          </MDTypography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MDTypography
                        variant="body2"
                        fontWeight="bold"
                        style={{ textAlign: "left" }}
                      >
                        Summary:
                      </MDTypography>
                      <MDTypography
                        variant="body2"
                        fontWeight="italic"
                        style={{
                          textAlign: "left",
                          marginBottom: 18,
                          marginTop: 8,
                        }}
                      >
                        {item.summary}
                      </MDTypography>
                      {item.url && (
                        <MDTypography
                          variant="body2"
                          fontWeight="italic"
                          color="primary"
                        >
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Original Source
                          </a>
                        </MDTypography>
                      )}
                      <MDTypography
                        variant="body2"
                        fontWeight="bold"
                        style={{ textAlign: "left", marginTop: 18 }}
                      >
                        Alignment:
                      </MDTypography>
                      <MDTypography variant="body2" style={{ marginTop: 8 }}>
                        {item.response}
                      </MDTypography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </MDBox>
  );
};

export default MPInfo;
