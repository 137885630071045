// Kilner Tech Dashboard React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import pxToRem from "assets/theme/functions/pxToRem";

const { white } = colors;
const { size } = typography;

const contained = {
  base: {
    minHeight: pxToRem(40),
    color: white.main,  // Set the text color to white
    background: "linear-gradient(256deg, #49A3F1 0%, #1A73E8 100%)",  // Set the background to the specified gradient
    padding: `${pxToRem(10)} ${pxToRem(24)}`,

    "&:hover": {
      background: "linear-gradient(256deg, #49A3F1 0%, #1A73E8 100%)",  // Keep the gradient on hover
      opacity: 0.9,  // Slight opacity change on hover
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.xs,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(12)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: size.sm,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    background: "linear-gradient(256deg, #49A3F1 0%, #1A73E8 100%)",  // Set the primary background to the gradient

    "&:hover": {
      background: "linear-gradient(256deg, #49A3F1 0%, #1A73E8 100%)",  // Keep the gradient on hover
    },
  },

  secondary: {
    background: "linear-gradient(256deg, #49A3F1 0%, #1A73E8 100%)",  // Set the secondary background to the gradient

    "&:hover": {
      background: "linear-gradient(256deg, #49A3F1 0%, #1A73E8 100%)",  // Keep the gradient on hover
    },
  },
};

export default contained;
