import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from '@mui/material/Tooltip';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Avatar,
  Chip,
  List,
  ListItem,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  InputAdornment,
  FormControl,
  InputLabel,
  CircularProgress
} from "@mui/material";
import {
  Facebook,
  Twitter,
  Instagram,
  Email,
  Public,
} from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
// import Typical from "react-typical";
import { ClearableTextField } from "customizedComponents/ClearableTextField";
import CloseIcon from "@mui/icons-material/Close";
import { buildURL, rootAPI, callAPI } from "api/callAPI";
import Markdown from "react-markdown";
import { useMessagesScroll } from "layouts/chat/hooks";
import LoadingButton from "components/LoadingButton";
import { useNavigate } from "react-router-dom";
import MDTypography from "components/MDTypography";

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const replaceXmlWithHtml = (url) => 
  url?.endsWith(".xml") ? url.slice(0, -4) + ".html" : url;

const getDocumentColor = (type) => {
  switch (type) {
    case "questions":
      return { color: "primary", custom: false };
    case "debates":
      return { color: "secondary", custom: false };
    case "explanations":
      return { color: "#61b6cf", custom: true };
    default:
      return { color: "default", custom: false };
  }
};

const parseQuestions = (questionsString) => {
  try {
    let cleanedString = questionsString
      .replace(/^\[|\]$/g, "")
      .replace(/'/g, '"');
    const parsed = JSON.parse(`[${cleanedString}]`);
    if (Array.isArray(parsed)) {
      return parsed;
    } else {
      throw new Error("Parsed data is not an array");
    }
  } catch (error) {
    console.error("Error parsing questions:", error);
    return [];
  }
};

const FeedItem = ({ loading, data, filterMP }) => {
  const navigate = useNavigate();
  const [addPromptOpen, setAddPromptOpen] = useState(false); // State for confirmation dialog
  const commonStyle = { fontSize: "14px", color: "textSecondary", width: "200px"};

  const handleCloseAddPromptDialog = () => {
    setAddPromptOpen(false); // Close confirmation dialog
    setFormat("");
    setTone("");
    setResponse("");
    setAdditionalInfo("");
  };
  const [savedItem, setsavedItem] = useState();
  const handleOpenAddPromptDialog = (item) => {
    setAddPromptOpen(true);
    setsavedItem(item);
  };

  const [Format, setFormat] = useState("tweet");
  const [Response, setResponse] = useState("");
  const [AdditionalInfo, setAdditionalInfo] = useState("");
  const [Tone, setTone] = useState("");
  const scrollRef = useRef(null);
  const { messagesEndRef } = useMessagesScroll(Response);
  const [rebuttalLoading, setRebuttalLoading] = useState(false);

  const handleFormatChange = (item) => {
    setFormat(item.target.value);
    if (item.target.value === "email") {
      setTone(
        " Formal and supportive, with detailed reasoning and clear structure."
      );
    } else if (item.target.value === "linkedin") {
      setTone(
        "Professional yet engaging, encouraging further discourse on the topic."
      );
    } else if (item.target.value === "x") {
      setTone("Concise, impactful, and direct with a clear call to action.");
    }
  };
  const [buttonDisable, setbuttonDisable] = useState(false);

  const handleCancelEdit = () => {
    setResponse("");
  };

  const handleGenerate = async () => {
    try {
      setbuttonDisable(true);
      setRebuttalLoading(true);
      const body_request = JSON.stringify({
        intervention_summary: savedItem.summary,
        fact_check_response: savedItem.response,
        mepName: savedItem.name,
        platform_type: Format,
        additional_facts: AdditionalInfo,
        tone: Tone,
      });
      const response = await callAPI(
        buildURL(rootAPI, "rebuttal/generate_rebuttal"),
        "POST",
        body_request,
        navigate
      );
      setResponse(response.response);

      if (!response) {
        throw new Error(`HTTP error! Rebuttal machine failed!`);
      }
    } catch (error) {
      console.error("error: ", error);
    }
    setbuttonDisable(false);
    setRebuttalLoading(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }, 1000); // 1-second timeout

    return () => clearTimeout(timer);
  }, [Response]);

  const [expandedItem, setExpandedItem] = useState(null);

  if (loading) {
    return         <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="50vh"
                  >
                    <CircularProgress />
            </Box>;
  }

  const handleExpandClick = (index) => {
    setExpandedItem(expandedItem === index ? null : index);
  };

  const handleAvatarClick = (id, term) => {

    navigate(`/MEP-Info/${term}/${id}`);
  };
  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      {data.map((item, index) => {
        const documentColor = getDocumentColor(item.typeDocument);
        const isSelected = filterMP === item.name;

        return (
          <Card
            key={index}
            sx={{
              mb: 2,
              p: 2,
              display: "flex",
              flexDirection: "column",
              width: "100% !important",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { xs: "column", sm: "row" },
                gap: "1rem",
                width: "100% !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                  // width: "100% !important",
                }}
              >
                {/* Avatar Image with click handling */}
                {item.imgLink ? (
                  <Avatar
                    alt={item.name}
                    src={item.imgLink}
                    onClick={() => handleAvatarClick(item.mepID, item.term)}
                    sx={{
                      width: 84,
                      height: 84,
                      mr: 2,
                      border: isSelected ? "3px solid #1976d2" : "none",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <Avatar
                    onClick={() => handleAvatarClick(item.mepID, item.term)}
                    sx={{
                      width: 84,
                      height: 84,
                      mr: 2,
                      border: isSelected ? "3px solid #1976d2" : "none",
                      cursor: "pointer",
                    }}
                  >
                    {item.name ? item.name[0] : "?"}
                  </Avatar>
                )}
                {/* Title and MP Name */}
                <Box>
                  <Typography
                    sx={{ fontSize: "12px" }}
                    variant="h6"
                    gutterBottom
                  >
                    {item.name ? toTitleCase(item.name) : "No Name"}
                    &nbsp;{" "}
                    {!item.termsActivity.includes(10) && (
                      <MDTypography
                        sx={{ color: "grey" }}
                        style={{ whiteSpace: "nowrap" }} // Ensures the text stays on the same line
                        fontWeight="italic"
                        fontStyle="italic"
                        fontSize="12px"
                      >
                        (Past MEP)
                      </MDTypography>
                    )}
                  </Typography>

                  <Typography
                    sx={{ fontSize: "12px" }}
                    variant="body2"
                    color="textSecondary"
                  >
                    {item.date || "No Date"}
                  </Typography>
                  {item.typeDocument && (
                    <Chip
                      label={
                        item.typeDocument.charAt(0).toUpperCase() +
                        item.typeDocument.slice(1)
                      }
                      color={
                        documentColor.custom ? undefined : documentColor.color
                      } // Use the predefined color if not custom
                      size="small"
                      sx={{
                        mt: 1,
                        backgroundColor:
                          item.typeDocument === "explanations"
                            ? "#247ba0"
                            : item.typeDocument === "debates"
                            ? "#247ba0"
                            : "#247ba0",
                        color: documentColor.custom ? "#fff" : undefined,
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Divider
                orientation="vertical"
                sx={{
                  borderColor: "black",
                  height: "auto",
                  alignSelf: "stretch",
                }}
              />

              <Box sx={{ textAlign: "left" }}>
                <Typography
                  sx={{ fontSize: "12px" }}
                  variant="body2"
                  color="textSecondary"
                >
                  {item.group || "No Group"}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px" }}
                  variant="body2"
                  color="textSecondary"
                >
                  {item.party || "No Party"}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px" }}
                  variant="body2"
                  color="textSecondary"
                >
                  {item.country || "No Country"}
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                sx={{
                  borderColor: "black",
                  height: "auto",
                  alignSelf: "stretch",
                }}
              />

            <Box sx={{ textAlign: "left" }}>
              {/* <Tooltip title="Average level of concern based on all the statements made by this MEP about your topic, 0-100.">
                <Typography
                  sx={{ fontSize: "12px", display: "flex", alignItems: "center" }}
                  variant="body2"
                  color="textSecondary"
                >
                  Average level Of Concern : {Math.round(item.levelOfConcern * 10) / 10}
                </Typography>
              </Tooltip> */}
              
              <Typography
                sx={{ fontSize: "12px" }}
                variant="body2"
                color="textSecondary"
              >
                Total Interventions : {item.totalInterventions}
              </Typography>
              
              <Tooltip title="Influence of the MEP in the Parliament according to their roles and responsibilities, 0-100.">
                <Typography
                  sx={{ fontSize: "12px", display: "flex", alignItems: "center" }}
                  variant="body2"
                  color="textSecondary"
                >
                  Impact Score : {item.impactScore}
                </Typography>
              </Tooltip>
            </Box>
            <Divider
              orientation="vertical"
              sx={{
                borderColor: "black",
                height: "auto",
                alignSelf: "stretch",
              }}
            />

              {/* Party and Group */}

              <Box sx={{ textAlign: "left" }}>
                {item.email && (
                  <IconButton
                    href={`mailto:${item.email}`}
                    aria-label="email"
                  >
                    <Email />
                  </IconButton>
                )}
                {item.facebook && (
                  <IconButton
                    href={item.facebook}
                    aria-label="facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook />
                  </IconButton>
                )}
                {item.twitter && (
                  <IconButton
                    href={item.twitter}
                    aria-label="twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Twitter />
                  </IconButton>
                )}
                {item.instagram && (
                  <IconButton
                    href={item.instagram}
                    aria-label="instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram />
                  </IconButton>
                )}
                {item.homePage && (
                  <IconButton
                    href={item.homePage}
                    aria-label="homepage"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Public />
                  </IconButton>
                )}
                <Box onClick={() => handleOpenAddPromptDialog(item)}>
                  <Button
                    onClick={() => handleOpenAddPromptDialog(item)}
                    sx={{
                      // p: 2,
                      mb: 1,
                      textDecoration: "underline",
                      fontSize: "10px",
                      fontWeight: "bold",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Generate Response
                  </Button>
                </Box>
              </Box>
            </CardContent>

            <CardContent sx={{ mt: "-50px" }}>
              {/* Title linked to URL */}
              <Divider sx={{ width: "100%" }} />
              {item.title && (
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{ color: "black" }}
                  sx={{ fontSize: "20px" }}
                >
                  {item.url ? (
                    <a
                      href={replaceXmlWithHtml(item.url)}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "inherit", textDecoration: "none" }} // inherit color from Typography
                    >
                      {item.title}
                    </a>
                  ) : (
                    item.title
                  )}
                </Typography>
              )}
              <Divider sx={{ width: "100%" }} />
              <Box sx={{ mt: "-20px" }}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{ color: "#1A73E8", fontWeight: "500" }}
                >
                  <span
                    style={{
                      borderBottom: "1px solid #e5e5e5",
                      fontSize: "14px",
                    }}
                  >
                    Summary:
                  </span>
                </Typography>
              </Box>
              <Typography
                sx={{ lineHeight: "20px", fontSize: "14px" }}
                variant="body2"
                paragraph
              >
                {item.summary || "No Summary"}
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ color: "#1A73E8", fontWeight: "500" }}
              >
                <span
                  style={{
                    borderBottom: "1px solid #e5e5e5",
                    fontSize: "14px",
                  }}
                >
                  Alignment:
                </span>
              </Typography>
              <Typography
                variant="body2"
                paragraph
                sx={{
                  fontWeight: "bold",
                  lineHeight: "20px",
                  fontSize: "14px",
                }}
              >
                {item.response || "No Response"}
              </Typography>

              <div style={{ padding: '2px 0' }}> {/* Adds padding between the metrics */}
                <Tooltip 
                  title="Indicates the extent to which intervention aligns with factual information. Scale: 0 (minimum) to 100 (maximum)."
                  placement="bottom"
                >
                  <Typography variant="body2" sx={commonStyle}>
                    Level of Concern:{" "}
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {Math.floor(item.levelOfConcern)}
                    </span>
                  </Typography>
                </Tooltip>
              </div>

              <div style={{ padding: '2px 0' }}> {/* Adds padding between the metrics */}
                <Tooltip 
                  title="Measures the relevance of the document to the specified Area of Search. Scale: 0 (not relevant) to 1 (highly relevant)."
                  placement="bottom"
                >
                  <Typography variant="body2" sx={commonStyle}>
                    Relevance Score:{" "}
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {item.relevanceScore.toFixed(2)} {/* Formats to two decimal places */}
                    </span>
                  </Typography>
                </Tooltip>
              </div>

              {/* Conditional Rendering for Questions, Explanations, and Debates */}
              {item.typeDocument && (
                <Box>
                  {item.typeDocument === "questions" && (
                    <>
                      <Typography
                        variant="subtitle2"
                        onClick={() => handleExpandClick(index)}
                        sx={{ mb: 1 ,pt: 2}}
                      >
                        <span
                          style={{
                            color: "#1A73E8",
                            borderBottom: "1px solid #1A73E8",
                            cursor: "pointer",
                          }}
                        >
                          {expandedItem === index
                            ? "Hide Details"
                            : "Further Details"}
                        </span>
                      </Typography>
                      {/* </DynamicButton> */}
                      {expandedItem === index && (
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="subtitle1" gutterBottom>
                            Intro
                          </Typography>
                          <Typography variant="body2" paragraph>
                            {item.intro || "No Intro"}
                          </Typography>
                          {item.questions && item.questions !== "[]" ? (
                            <>
                              <Typography variant="subtitle1" gutterBottom>
                                Questions
                              </Typography>
                              <List>
                                {parseQuestions(item.questions).map(
                                  (question, idx) => (
                                    <ListItem key={idx}>
                                      <Typography variant="body2" paragraph>
                                        {question}
                                      </Typography>
                                    </ListItem>
                                  )
                                )}
                              </List>
                            </>
                          ) : null}
                        </Box>
                      )}
                    </>
                  )}

                  {item.typeDocument === "explanations" && (
                    <Box sx={{ mt: 1 }}>
                      <Typography
                        variant="subtitle2"
                        onClick={() => handleExpandClick(index)}
                        // sx={{ mb: 1 }}
                      >
                        <span
                          style={{
                            color: "#1A73E8",
                            borderBottom: "1px solid #1A73E8",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                        >
                          Further Details
                        </span>
                      </Typography>

                      {/* </DynamicButton> */}
                      {expandedItem === index && (
                        <Box sx={{ mt: 1 }}>
                          <Typography
                            sx={{ fontSize: "14px" }}
                            variant="h6"
                            gutterBottom
                          >
                            Explanation of Vote
                          </Typography>
                          <Typography
                            sx={{ fontSize: "14px", lineHeight: "20px" }}
                            variant="body2"
                            paragraph
                          >
                            {item.textTranslated || "No Explanation"}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}

                  {item.typeDocument === "debates" && (
                    <Box sx={{ mt: 1 }}>

                      <Typography
                        variant="subtitle2"
                        onClick={() => handleExpandClick(index)}
                        sx={{ mb: 1 }}
                      >
                        <span
                          style={{
                            color: "#1A73E8",
                            borderBottom: "1px solid #1A73E8",
                            cursor: "pointer",
                          }}
                        >
                          Further Details
                        </span>
                      </Typography>
                      {/* </DynamicButton> */}
                      {expandedItem === index && (
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="h6" gutterBottom>
                            Intervention
                          </Typography>
                          <Typography variant="body2" paragraph>
                            {item.textTranslated || "No Debate Text"}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </CardContent>
            {/* Original Source Link at the end of the card */}
            {item.url && (
              <CardContent>
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ textAlign: "center" }}
                >
                  <a
                    href={replaceXmlWithHtml(item.url)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Original Source
                  </a>
                </Typography>
              </CardContent>
            )}
          </Card>
        );
      })}

      {/* ////////////////////////////////////using material///////////////////// ui  */}

      <Dialog open={addPromptOpen} onClose={handleCloseAddPromptDialog}>
        <DialogTitle
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Generate Response
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseAddPromptDialog}
            // sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth sx={{ width: "100%", mt: 1 }}>
              <InputLabel
                id="dropdown-prompt-label"
                // sx={{ color: "#fff !important" }}
              >
                Format
              </InputLabel>
              <Select
                labelId="dropdown-query-label"
                label="Format"
                variant="outlined"
                disableUnderline
                //  bgColor="info"
                id="demo-simple-select"
                value={Format}
                onChange={(item) => handleFormatChange(item)}
                sx={{ width: "100%", height: "50px" }}
              >
                <MenuItem
                  sx={{
                    width: "100%",
                    height: "100%",
                    color: "white",
                    backgroundColor: "transparent",
                  }}
                  value={"x"}
                >
                  X
                </MenuItem>
                <MenuItem
                  sx={{
                    width: "100%",
                    height: "100%",
                    color: "white",
                    backgroundColor: "transparent",
                  }}
                  value={"email"}
                >
                  Email
                </MenuItem>
                <MenuItem
                  sx={{
                    width: "100%",
                    height: "100%",
                    color: "white",
                    backgroundColor: "transparent",
                  }}
                  value={"linkedin"}
                >
                  LinkedIn
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Add Tone"
              type="text"
              value={Tone}
              onChange={(e) => setTone(e.target.value)}
              // InputLabelProps={{ shrink: true }}
              sx={{ my: 2, width: "100%" }}
              // multiline={true}
              // row={4}
            />

            <ClearableTextField
              value={AdditionalInfo}
              variant="outlined"
              label="Additional Information"
              multiline
              row={8} // Minimum number of rows (1 line)
              rowsMax={10}
              fullWidth
              // value={inputText}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCancelEdit}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {Response !== "" && (
            <Box
              sx={{
                width: "100%",
                mt: 3,
                border: "1px solid #d2d6da",
                borderRadius: "0.375rem",
                p: 1,
                position: "relative", // Position relative for button placement
              }}
            >
              {/* Copy button */}
              <CopyToClipboard text={Response}>
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: "#f5f5f5",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                  }}
                  aria-label="copy"
                >
                  <ContentCopyIcon />
                </IconButton>
              </CopyToClipboard>

              <Markdown>{Response}</Markdown>
              <div ref={messagesEndRef} />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ my: 2 }}>
          <Button
            onClick={handleCloseAddPromptDialog}
            variant="outlined"
            sx={{
              color: "black !important", // Set the text color to black
              borderColor: "black !important", // Set the border color to black
              "&:hover": {
                borderColor: "black", // Ensure the border remains black on hover
                color: "black", // Ensure the text remains black on hover
              },
              marginRight: 1,
            }}
          >
            Cancel
          </Button>

          <LoadingButton
            disabled={buttonDisable}
            variant="contained"
            onClick={handleGenerate}
            color="primary"
            loading={rebuttalLoading}
          >
            Generate
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FeedItem;
