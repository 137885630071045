import { useState, useCallback } from "react";
import PropTypes from "prop-types";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
// hooks
import { useResponsive } from "../../../src/hooks/use-responsive";
// api
import Iconify from "components/Iconify";
// import ConfirmDialog from "components/ConfirmDialog"; // Import ConfirmDialog

export default function ChatNavItem({
  selected,
  chatID,
  chatTitle,
  collapse,
  conversation = {}, // Default to an empty object
  onSelect, // Add onSelect prop
  onCloseMobile,
  onSelectConversation,
  onChatDelete,
}) {
  const [selectedId, setSelectedId] = useState(null); // State to track selected ID
  const [isHovered, setIsHovered] = useState(false);
  const mdUp = useResponsive("up", "md");
  const handleClickConversation = useCallback(
    (id) => {
      try {
        setSelectedId(id); // Update selectedId when a conversation is clicked
        onSelectConversation(id);

        if (!mdUp) {
          onCloseMobile();
        }

        // Your existing click handling logic
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mdUp, onCloseMobile, onSelect]
  );

  const handleDeleteClick = (chatID,chatTitle) => {
    onChatDelete(chatID,chatTitle);
  };

  return (
    <>
      {!collapse && (
        <Box
          sx={{
            maxHeight: "calc(100vh - 64px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {/* {LLMOutput.map((chat) => ( */}
          <ListItemButton
            key={conversation.chatID}
            disableGutters
            onClick={() => handleClickConversation(conversation.chatID)} // Pass the chatID to the handler
            onMouseEnter={() => setIsHovered(true)} // Set hover state on mouse enter
            onMouseLeave={() => setIsHovered(false)} // Reset hover state on mouse leave
            sx={{
              py: 1.5,
              px: 2.5,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "flex-start", // Align items to the start
              ...(selected &&
                selectedId === conversation.chatID && {
                  bgcolor: "action.selected",
                }),
            }}
          >
            <Box>
              {!collapse && (
                <Stack spacing={1} sx={{ ml: 2 }}>
                  <ListItemText
                    primary={
                      conversation?.chatTitle?.length > 18
                        ? `${conversation.chatTitle.slice(0, 18)}...`
                        : conversation.chatTitle
                    }
                    primaryTypographyProps={{
                      noWrap: true,
                      variant: "subtitle2",
                    }}
                  />
                </Stack>
              )}
            </Box>
            <Box>
              {isHovered && (
                <Box>
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteClick(chatID,chatTitle);
                    }}
                  >
                    <Iconify
                      icon="solar:trash-bin-trash-bold"
                      sx={{ color: "text.secondary" }}
                    />
                  </IconButton>
                </Box>
              )}
            </Box>
          </ListItemButton>
        </Box>
      )}
    </>
  );
}

ChatNavItem.propTypes = {
  selected: PropTypes.bool,
  chatID: PropTypes.string,
  chatTitle: PropTypes.string,
  collapse: PropTypes.bool,
  conversation: PropTypes.object,
  onSelect: PropTypes.func,
  onCloseMobile: PropTypes.func,
  onSelectConversation: PropTypes.func,
  onChatDelete: PropTypes.func,
};
