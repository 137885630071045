import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { formatDistanceToNowStrict } from "date-fns";
import Markdown from "react-markdown";
import Plot from "react-plotly.js";
import { Box, Stack, Avatar, Typography } from "@mui/material";
import { BeatLoader } from "react-spinners";
import { useGetMessage } from "./hooks";
import { useUser } from "context";

// Helper function to format the timestamp
const formatDate = (timestamp) => {
  try {
    const parsedDate = new Date(timestamp * 1000);
    return formatDistanceToNowStrict(parsedDate, { addSuffix: true });
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Unknown time";
  }
};

// Helper function to extract the JSON block from the content
const extractJsonFromContent = (text) => {
  const jsonRegex = /```json\s*([\s\S]*?)\s*```/;
  const match = text.match(jsonRegex);
  if (match && match[1]) {
    try {
      const jsonObject = JSON.parse(match[1]);
      return jsonObject;
    } catch (error) {
      console.error("Failed to parse JSON:", error);
    }
  }
  return null;
};

// Helper function to remove the JSON block from the content for rendering the rest as Markdown
const removeJsonFromContent = (text) => {
  return text.replace(/```json\s*([\s\S]*?)\s*```/, "").trim();
};

// Helper to get user avatar URL
const getAvatarUrl = (author, userProfile, defaultAvatar) => {
  if (author === "ai") {
    return "https://img.freepik.com/free-vector/chatbot-chat-message-vectorart_78370-4104.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1725667200&semt=ais_hybrid";
  }
  return userProfile?.photoURL || defaultAvatar;
};

// Render information about the message sender and timestamp
const RenderMessageInfo = ({ author, formattedDate, me }) => (
  <Typography
    noWrap
    variant="caption"
    sx={{
      mt: 1,
      color: "text.disabled",
      ...(!me && {
        mr: "auto",
      }),
    }}
  >
    {author === "ai" ? "Bot" : "You"} &nbsp;
    {formattedDate}
  </Typography>
);

// Render the message body, either as Markdown or a Plotly plot if JSON data is found
const RenderMessageBody = ({ author, content, temp, me, hasImage }) => {
  const [plotData, setPlotData] = useState(null);
  const [messageWithoutJson, setMessageWithoutJson] = useState(content);

  useEffect(() => {
    const json = extractJsonFromContent(content);
    setPlotData(json);
    setMessageWithoutJson(removeJsonFromContent(content));
  }, [content]);

  return (
    <Stack
      sx={{
        p: 1.5,
        minWidth: 48,
        mr: 2,
        borderRadius: 1,
        typography: "body2",
        bgcolor: "background.neutral",
        ...(me && {
          color: "grey.800",
          bgcolor: "primary.lighter",
        }),
        ...(hasImage && {
          p: 0,
          bgcolor: "transparent",
        }),
      }}
    >
      {author === "ai" && temp ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>Processing</Box>
          <BeatLoader color="blue" loading size={8} />
        </Box>
      ) : plotData ? (
        <Box sx={{ width: "800px", height: "400px" }}> {/* Ensure container has width */}
          <Plot
            data={plotData.data}
            layout={plotData.layout}
          />
        </Box>
      ) : (
        <Markdown>{messageWithoutJson}</Markdown>
      )}
    </Stack>
  );
};

const ChatMessageItem = ({ message, participants, newmessages }) => {
  // Default user data, fallback if userProfile is not available
  const defaultUser = {
    id: "8864c717-587d-472a-929a-8e5f298024da-0",
    displayName: "Jaydon Frankie",
    email: "demo@minimals.cc",
    photoURL:
      "https://png.pngtree.com/png-vector/20190704/ourmid/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg",
  };

  const { author, content, timestamp, temp } = message;
  const { me, senderDetails, hasImage } = useGetMessage({
    message: { content, createdAt: timestamp },
    participants,
    currentUserId: "test", // Adjust this to fetch dynamically if needed
  });
  
  const { firstName } = senderDetails || {};
  const formattedDate = formatDate(timestamp);

  const { userProfile } = useUser(); // Fetch user profile context

  return (
    <Stack direction="row" justifyContent="unset" sx={{ mb: 1, ml: 2, mt: 1 }}>
      {!me && (
        <Avatar
          alt={firstName || "User"}
          src={getAvatarUrl(author, userProfile, defaultUser.photoURL)}
          sx={{ width: 32, height: 32, mr: 2 }}
        />
      )}

      <Stack alignItems="flex-end">
        <RenderMessageInfo author={author} formattedDate={formattedDate} me={me} />

        <Stack
          direction="row"
          alignItems="center"
          sx={{
            position: "relative",
            "&:hover": {
              "& .message-actions": {
                opacity: 1,
              },
            },
          }}
        >
          <RenderMessageBody
            author={author}
            content={content}
            temp={temp}
            me={me}
            hasImage={hasImage}
          />
          {/* Message actions can be added here */}
        </Stack>
      </Stack>
    </Stack>
  );
};

// Prop validation for message item
ChatMessageItem.propTypes = {
  message: PropTypes.shape({
    author: PropTypes.string.isRequired,
    chatID: PropTypes.string.isRequired,
    chatTitle: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    messageID: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
  }).isRequired,
  participants: PropTypes.array.isRequired,
  newmessages: PropTypes.string.isRequired,
};

export default ChatMessageItem;
