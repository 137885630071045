/**
=========================================================
* Kilner Tech Dashboard
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Kilner Tech Dashboard React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Kilner Tech Dashboard React themes
import theme from "assets/theme";

// Kilner Tech Dashboard React Dark Mode themes
import themeDark from "assets/theme-dark";

// Kilner Tech Dashboard React routes
import routes from "routes";

// Kilner Tech Dashboard React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import UserProfileView from "layouts/user-details";
import { useUser } from "context";
import { cloneDeep } from "lodash";

export default function App() {
  const [appRoutes, setAppRoutes] = useState(routes); // For URL specific
  const [appRoutesNav, setAppRoutesNav] = useState(routes); // For Sidenav

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const { userProfile } = useUser();

  useEffect(() => {

    // accounts with NO admin privileges 
    const blacklistedDemo = ["demofood@kilner.tech",
                             "democleanenergy@kilner.tech",
                             "demoev@kilner.tech"
                            ];
    let filteredRoutes = cloneDeep(routes);
    if (!userProfile?.email?.endsWith("@kilner.tech") || 
        blacklistedDemo.includes(userProfile?.email)
    ) {
      filteredRoutes = routes.filter((item) => item.name !== "Admin");
    }
    setAppRoutes(cloneDeep(filteredRoutes));
    filteredRoutes = filteredRoutes.filter((item) => item.key !== "sign-in");
    setAppRoutesNav(filteredRoutes);
  }, [userProfile?.email]);

  // Cache for the rtl

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            sx={{
              ".MuiDrawer-paper": { backgroundColor: "#edf2f9" },
              ".MuiPaper-root": { height: "100vh", marginTop: 0 },
            }}
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Kilner Tech"
            routes={appRoutesNav}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator /> */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(appRoutes)}
        <Route path="*" element={<Navigate to="/feed" />} />
        <Route path="/MEP-Info/:term/:id" element={<UserProfileView />} />
      </Routes>
    </ThemeProvider>
  );
}
