import PropTypes from "prop-types";
import { useRef, useState, useCallback, useEffect } from "react";
import InputBase from "@mui/material/InputBase";
import SendIcon from "@mui/icons-material/Send";
import { rootAPI, callAPI, buildURL } from "api/callAPI";
import uuidv4 from "../../../src/utils/uuidv4";
import { useNavigate } from "react-router-dom";
export default function ChatMessageInput({
  onAddRecipients,
  selectedConversationId,
  onSelectConversation,
  fetchLLMResponses,
  handleNewMessage,
}) {
  const fileRef = useRef(null);
  const [message, setMessage] = useState("");
  const [messageSending, setMessageSending] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchLLMResponses(selectedConversationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversationId]);

  const handleChangeMessage = useCallback((event) => {
    setMessage(event.target.value);
  }, []);

  const handleSendMessage = useCallback(
    async (event) => {
      if (messageSending || !selectedConversationId) return;

      if (event.key === "Enter") {
        if (event.shiftKey) {
          event.preventDefault();
          setMessage((prevText) => prevText + "\n");
        } else {
          try {
            setMessageSending(true);
            if (event.key === "Enter" || event.type === "click") {
              handleNewMessage(message);
              setMessage("");

              if (message) {
                const chatId = selectedConversationId || uuidv4();
                const data = {
                  chatTitle: "TESTT",
                  chat_id: chatId,
                  message: message,
                };
                onSelectConversation(chatId);
                const body_request = JSON.stringify(data);
                const response = await callAPI(
                  buildURL(rootAPI, "chat/ask_agent"),
                  "POST",
                  body_request,
                  navigate
                );                
                if (!response) {
                  throw new Error("Failed to send message");
                }

                fetchLLMResponses(chatId);

                setMessage("");
              }
            }
          } catch (error) {
            console.error("Error in sending message:", error);
          }
        }
      }

      setMessageSending(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [message, selectedConversationId, onAddRecipients]
  );

  return (
    <>
      <InputBase
        disabled={messageSending || !selectedConversationId}
        value={message}
        onKeyDown={handleSendMessage}
        onChange={handleChangeMessage}
        multiline
        maxRows={3} // Limit the number of rows
        placeholder="Type a message"
        endAdornment={
          <SendIcon
            sx={{ cursor: "pointer", color: "#000", mr: 1, ml: 1 }}
            onClick={handleSendMessage}
          />
        }
        sx={{
          px: 1,
          pt: 2,
          minHeight: 46, // Minimum height of the input box
          maxHeight: 150, // Max height to prevent uncontrolled expansion
          overflow: "auto", // Ensure scroll if max height is exceeded
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
          borderRadius: 1, // Optional: to make it look more polished
        }}
      />
      <input type="file" ref={fileRef} style={{ display: "none" }} />
    </>
  );
}

ChatMessageInput.propTypes = {
  disabled: PropTypes.bool,
  onAddRecipients: PropTypes.func,
  recipients: PropTypes.array,
  selectedConversationId: PropTypes.string,
  onSelectConversation: PropTypes.func,
  fetchLLMResponses: PropTypes.func.isRequired,
  handleNewMessage: PropTypes.func.isRequired,
};
