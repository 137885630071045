// ----------------------------------------------------------------------

export default function useGetNavItem({ currentUserId, conversation }) {
  // Default to empty arrays or objects to avoid undefined errors
  const messages = conversation?.messages || [];
  const participants = conversation?.participants || [];

  // Filter participants excluding the current user
  const participantsInConversation = participants.filter(
    (participant) => participant.id !== currentUserId
  );

  // Get the last message if available
  const lastMessage = messages[messages.length - 1] || {};

  // Determine if the conversation is a group chat
  const group = participantsInConversation.length > 1;

  // Generate display name for group conversations
  const displayName = participantsInConversation
    .map((participant) => participant.name || "Unknown")
    .join(", ");

  // Check if there is an online participant in the group
  const hasOnlineInGroup = group
    ? participantsInConversation.some((item) => item.status === "online")
    : false;

  // Determine the display text based on the last message
  let displayText = "";

  if (lastMessage) {
    const sender = lastMessage.senderId === currentUserId ? "You: " : "";

    const message =
      lastMessage.contentType === "image"
        ? "Sent a photo"
        : lastMessage.body || "";

    displayText = `${sender}${message}`;
  }

  return {
    group,
    displayName,
    displayText,
    participants: participantsInConversation,
    lastActivity: lastMessage.createdAt || new Date(),
    hasOnlineInGroup,
  };
}
