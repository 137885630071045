/**
=========================================================
* Kilner Tech Dashboard
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
// @mui icons
import GoogleIcon from "@mui/icons-material/Google";

// Kilner Tech Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Button from "@mui/material/Button";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Divider } from "@mui/material";
import { useHandleLoginWithGoogle } from "api/authentication";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // State to control dialog visibility
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleLoginWithGoogle = useHandleLoginWithGoogle(setOpenDialog);
  const handleCloseDialog = () => setOpenDialog(false); // Close dialog handler

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                // onClick={fakeLogin}
              >
                sign in
              </MDButton>
            </MDBox>

            <MDBox mt={3} mb={1} textAlign="center">
              <Divider sx={{ m: 0.5 }} />
              <MDTypography variant="body2" fontWeight="medium">
                OR
              </MDTypography>
              <Divider sx={{ m: 0.5 }} />
            </MDBox>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              disableRipple
              onClick={handleLoginWithGoogle}
            >
              <GoogleIcon />
              &nbsp; Sign In With Google
            </MDButton>
          </MDBox>
        </MDBox>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>User Not Authorized</DialogTitle>
          <DialogContent>
            You are not authorized to log in. Please contact the admin for access.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
