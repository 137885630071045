// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { callAPI, buildURL, rootAPI } from "api/callAPI";
// import {firebaseConfig} from  "api/firebaseConfig";
import { useUser } from "context";
import { useNavigate } from "react-router-dom";
import firebaseConfig from "./firebaseConfig";


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// Whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: "select_account",
});
provider.addScope("email");
const auth = getAuth(firebaseApp);

const signInWithGooglePopup = () => signInWithPopup(auth, provider);

const useHandleLoginWithGoogle = (setOpenDialog) => {
  const { login } = useUser(); // Call the hook at the top level
  const navigate = useNavigate();
  const handleLoginWithGoogle= async () => {
    try {
      const result = await signInWithGooglePopup();
      await callAPI(buildURL(rootAPI, "login"), "GET",null,navigate);
      // Save user profile
      const userData = {
        displayName: result.user.displayName,
        email: result.user.email,
        photoURL: result.user.photoURL,
      };
      login(userData);
      navigate(`/feed`);
    } catch (error) {
      console.error("Error logging in with Google:", error);
      setOpenDialog(true); // Show the dialog
    }
  };

  return handleLoginWithGoogle;
};

const useHandleSignOut = () => {
  const { logout } = useUser(); // Call the hook at the top level
  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        logout();
        getContacts();
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out:", error);
      });
  };
  return handleLogOut;
};

const getContacts = async () => {
  await auth.authStateReady();
  const currentUser = auth.currentUser;

  if (!currentUser) return false;
  return true;
};

const getFreshToken = async () => {
  // Get the Firebase auth object
  // const auth = getAuth(firebaseApp);
  // const user = auth.currentUser; // Check if the user is authenticated
  const user = auth.currentUser;
  if (user) {

    try {
      // Get a new token if needed
      const newToken = await user.getIdToken(); // Pass true to force token refresh
      return newToken;
    } catch (error) {
      console.error("Error while refreshing the token:", error);
      return null;
    }
  } else {
  console.warn("No authenticated user, unable to refresh token.");
  }
  return null; // In case the user is not authenticated
};



export { useHandleLoginWithGoogle, useHandleSignOut, getContacts, getFreshToken };
