import React, { useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { CircularProgress, Box } from "@mui/material";
import { ChatView } from "layouts/chat/view";

const Chat = () => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* Container for the main content */}
      <Box sx={{ width: "100%", mt: 4, p: 2 }}>
        {" "}
        {/* Added padding for spacing */}
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh" // Keeps the spinner vertically centered
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              mt: 4,
            }}
          >
            {/* <ChatBot /> */}
            <ChatView />
          </Box>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default Chat;
