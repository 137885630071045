// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import MDTypography from "components/MDTypography";

// ----------------------------------------------------------------------

const FLAGS = [
  {
    name: "Austria",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849717/EU%20flags/Austria_wuqqzl.png",
  },
  {
    name: "Belgium",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849717/EU%20flags/belgium_nkrsdt.png",
  },
  {
    name: "Bulgaria",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849717/EU%20flags/Bulgaria_pjoag4.png",
  },
  {
    name: "Croatia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849718/EU%20flags/Croatia_grkmtc.png",
  },
  {
    name: "Cyprus",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849718/EU%20flags/Cyprus_v3rql3.png",
  },
  {
    name: "Czech Republic",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849720/EU%20flags/Czech_Republic_kdrhdo.png",
  },
  {
    name: "Czechia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849720/EU%20flags/Czech_Republic_kdrhdo.png",
  },
  {
    name: "Denmark",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849720/EU%20flags/Denmark_wyhsob.png",
  },
  {
    name: "Estonia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849721/EU%20flags/Estonia_muswrh.png",
  },
  {
    name: "Finland",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849722/EU%20flags/Finland_lt2pig.png",
  },
  {
    name: "France",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849723/EU%20flags/France_ab2foq.png",
  },
  {
    name: "Germany",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849724/EU%20flags/Germany_wxdwwe.png",
  },
  {
    name: "Greece",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849724/EU%20flags/Greece_pctcjm.png",
  },
  {
    name: "Hungary",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849725/EU%20flags/HUngary_duhwii.png",
  },
  {
    name: "Ireland",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849726/EU%20flags/Ireland_tcrte8.png",
  },
  {
    name: "Italy",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849727/EU%20flags/Italy_pls9mk.png",
  },
  {
    name: "Latvia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849728/EU%20flags/Latvia_vzdkwo.png",
  },
  {
    name: "Lithuania",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849729/EU%20flags/Lithuania_v7ibxh.png",
  },
  {
    name: "Luxembourg",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849730/EU%20flags/Luxembourg_xccng6.png",
  },
  {
    name: "Malta",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849731/EU%20flags/Malta_gwnygh.png",
  },
  {
    name: "Netherlands",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849732/EU%20flags/Netherland_kwsyct.png",
  },
  {
    name: "Poland",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849733/EU%20flags/Poland_i2ujxz.png",
  },
  {
    name: "Portugal",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849734/EU%20flags/Portugal_dvevr1.png",
  },
  {
    name: "Romania",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849735/EU%20flags/ROmania_fwhqwj.png",
  },
  {
    name: "Slovakia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849736/EU%20flags/Slovakia_jlsdt6.png",
  },
  {
    name: "Slovenia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849737/EU%20flags/Slovenia_uqmuov.png",
  },
  {
    name: "Spain",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849738/EU%20flags/Spain_to7zux.png",
  },
  {
    name: "Sweden",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849738/EU%20flags/Sweden_hr04eq.png",
  },
];

export function bgGradient(props) {
  const direction = props?.direction || "to bottom";
  const startColor = props?.startColor;
  const endColor = props?.endColor;
  const imgUrl = props?.imgUrl;
  const color = props?.color;

  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${
        endColor || color
      }), url(${imgUrl})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    };
  }

  return {
    background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
  };
}

export default function ProfileCover({ data }) {
  const { name, imgLink, country, term, role } = data;
  const theme = useTheme();
  const matchedFlag = FLAGS.find((flagItem) => flagItem.name === country);

  return (
    <Box
      sx={{
        height: 1,
        background: `linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%), url(${matchedFlag?.flag})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{
          width: "100%",
          left: { md: 24 },
          bottom: { md: 24 },
          zIndex: { md: 10 },
          pt: { xs: 6, md: 0 },
          position: { md: "absolute" },
        }}
      >
        <Avatar
          src={imgLink}
          alt={name}
          sx={{
            mx: "auto",
            width: { xs: 64, md: 128 },
            height: { xs: 64, md: 128 },
            border: `solid 2px ${theme.palette.common.white}`,
          }}
        />

<ListItemText
  sx={{
    mt: 3,
    ml: { md: 3 },
    textAlign: { xs: "center", md: "unset" },
    ".css-1nmug8b-MuiTypography-root": {
      color: "white !important",
    },
    ".css-78lqg1-MuiTypography-root": {
      color: "white !important",
    },
    zIndex: 1000,
  }}
  primary={
    <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
      {name}
    </Box>
  }
  secondary={
    <MDTypography
      sx={{ color: "#FFF" }}
      style={{ whiteSpace: "nowrap" }} // Ensures the text stays on the same line
      fontWeight="italic"
      fontStyle="italic"
      fontSize="12px"
    >
      {role ? role : "Member"} - Term {term} {/* Use role or "Member" */}
    </MDTypography>
  }
  primaryTypographyProps={{
    typography: "h4",
  }}
  secondaryTypographyProps={{
    mt: 0.5,
    color: "inherit",
    component: "span",
    typography: "body2",
    sx: { opacity: 0.8 }, // Adjust opacity for secondary text
  }}
/>

      </Stack>
    </Box>
  );
}
