import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Autocomplete,
  Slider,
  Drawer,
  Tooltip
} from "@mui/material";
import Iconify from "layouts/user-details/Iconify";

const CustomDrawer = ({ open, setOpen, filterState, setFilterState }) => {
  const { filters, metadata, options } = filterState;

  const closeDrawer = () => {
    setOpen(false);
  };

  const handleFilterChange = (key, value) => {
    setFilterState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [key]: value,
      },
    }));
  };

  const renderFilter = (key,filter) => {
    switch (filter.type) {
      case "date":
        return (
          <TextField
            label={filter.label}
            type="date"
            value={filters[key] || ""}
            onChange={(e) => handleFilterChange(key, e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
        );

      case "slider":
        return (
          <Box key={key} sx={{ mb: 2 }}>
            {/* Display the slider label */}
            <Typography
              gutterBottom
              sx={{ fontSize: "0.875rem", fontWeight: "600", color: "#000000" }}
            >
              {filter.description || "Slider Filter"} {/* Default label if none is provided */}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* Tooltip for minimum value */}
              <Tooltip
                title={`Min: ${filters[key] ? filters[key][0] : filter.min}`}
                placement="top"
              >
                <Box sx={{ width: 50, textAlign: "center" }}>
                  <Typography variant="caption">
                    {filters[key] ? filters[key][0] : filter.min}
                  </Typography>
                </Box>
              </Tooltip>
              {/* Slider Component */}
              <Slider
                value={filters[key] || [filter.min, filter.max]}
                onChange={(e, newValue) => handleFilterChange(key, newValue)}
                valueLabelDisplay="auto"
                min={filter.min}
                max={filter.max}
                step={filter.step || 1}
              />
              {/* Tooltip for maximum value */}
              <Tooltip
                title={`Max: ${filters[key] ? filters[key][1] : filter.max}`}
                placement="top"
              >
                <Box sx={{ width: 50, textAlign: "center" }}>
                  <Typography variant="caption">
                    {filters[key] ? filters[key][1] : filter.max}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          </Box>
        );
      
      case "autocomplete":
        return (
          <Autocomplete
            multiple
            options={options[key]}
            getOptionLabel={(option) => option}
            value={filters[key] || []}
            onChange={(e, newValue) => handleFilterChange(key, newValue || [])}
            renderInput={(params) => (
              <TextField {...params} label={filter.label} variant="outlined" helperText={filter.description} />
            )}
            sx={{ mb: 2 }}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Drawer
      open={open}
      onClose={closeDrawer}
      anchor="right"
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "#fff",
          height: "100vh",
          width: 350,
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", mt: 2, p: 2 }}>
        {/* Header */}
        <Box
          sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pb: 1 }}
        >
          <Typography
            variant="body2"
            paragraph
            sx={{ fontSize: "1.25rem", fontWeight: "600", color: "#344767" }}
          >
            Filters
          </Typography>
          <IconButton onClick={closeDrawer}>
            <Iconify icon="gridicons:cross-small" width={24} sx={{ color: "#757575" }} />
          </IconButton>
        </Box>

        {/* Render Filters Dynamically */}
        {Object.entries(metadata).map(([key, value]) => renderFilter(key, value))}
        </Box>
    </Drawer>
  );
};

export default CustomDrawer;
