import React, { useState, useEffect } from "react";
import { Grid, Card, Box, Pagination } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import { callAPI, buildURL, rootAPI } from "api/callAPI";
import MDSearchInput from "./Searchfeild";
import IdentiKitCard from "./IdentiKitCards";
import { useNavigate } from "react-router-dom";

// Utility functions
function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

// Function to swap first name and last name
function swapNameOrder(name) {
  const [firstName, lastName] = name.split(" ");
  return `${lastName} ${firstName}`;
}

export function CreateAuthorsTableData(term,navigate) {
  const [mepList, setMepList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await callAPI(
          buildURL(rootAPI, `MEPs/mep_info?term=${term}`),
          "GET",
          null,
          navigate
        );
        setMepList(data.response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [term]);

  const rows = mepList.map((author, index) => ({
    id: index,
    mepID: author.mepID,
    mp: {
      imgLink: author.imgLink,
      name: toTitleCase(swapNameOrder(author.name)), // Swap name order and apply Title Case
      href: author.homepage,
    },
    country: author.country,
    party: author.party,
    group: author.group,
    contacts: author.homepage,
  }));

  // Sort rows by surname (last name) initially
  const sortedRows = [...rows].sort((a, b) => {
    const surnameA = a.mp.name.split(" ")[0].toLowerCase(); // Extract surname
    const surnameB = b.mp.name.split(" ")[0].toLowerCase(); // Extract surname
    return surnameA.localeCompare(surnameB);
  });

  const columns = [
    {
      field: "mp",
      headerName: "MP",
      width: 200,
      renderCell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDAvatar src={value.imgLink} name={value.name} size="sm" />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              <a
                href={value.href}
                rel="noopener noreferrer"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {value.name}
              </a>
            </MDTypography>
          </MDBox>
        </MDBox>
      ),
      sortComparator: (a, b) => {
        const surnameA = a.name.split(" ")[0].toLowerCase(); // Extract surname
        const surnameB = b.name.split(" ")[0].toLowerCase(); // Extract surname
        return surnameA.localeCompare(surnameB);
      },
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      renderCell: ({ value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value}
        </MDTypography>
      ),
    },
    {
      field: "party",
      headerName: "Party",
      flex: 1,
      renderCell: ({ value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value}
        </MDTypography>
      ),
    },
    {
      field: "group",
      headerName: "Group",
      flex: 1,
      renderCell: ({ value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value}
        </MDTypography>
      ),
    },
  ];

  return { columns, sortedRows };
}

function IdentikitTable() {
  const [term, setTerm] = useState("10"); // Default term is '10'
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate()
  const { columns, sortedRows } = CreateAuthorsTableData(term,navigate);
  const handleChange = (event) => {
    setTerm(event.target.value);
    setCurrentPage(1);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  // const [currentItems, setCurrentItems] = useState([]);

  const itemsPerPage = 9;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems = sortedRows;
  if (query) {
    const lowercasedTerm = query.toLowerCase();

    currentItems = sortedRows.filter(
      (item) =>
        item?.mp?.name.toLowerCase().includes(lowercasedTerm) ||
        item?.country.toLowerCase().includes(lowercasedTerm) ||
        item?.party.toLowerCase().includes(lowercasedTerm) ||
        item?.group.toLowerCase().includes(lowercasedTerm)
    );
  }
  currentItems = currentItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };
  return (
    <Grid item xs={12}>
      <Card sx={{ mt: 8 }}>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <Box sx={{ display: "flex", gap: "10px" }}>
            <MDSearchInput
              placeholder="Search..."
              onChange={handleQueryChange}
              value={query}
            />
            <Select
              variant="outlined"
              disableUnderline
              //  bgColor="info"
              sx={{
                height: "2.8rem",
                width: "30%",
                color: "white !important", // Text color
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white !important", // Border color
                },
                "& .MuiSelect-iconOutlined": {
                  color: "white", // Arrow color
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white", // Border color when focused
                },
                "& .MuiSvgIcon-root": {
                  color: "white", // Arrow color
                },
                ".css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    color: "white !important",
                    borderColor: "white !important",
                  },
              }}
              id="demo-simple-select"
              value={term}
              onChange={handleChange}
            >
              <MenuItem
                sx={{ color: "white", backgroundColor: "transparent" }}
                value={9}
              >
                Term 9
              </MenuItem>
              <MenuItem
                sx={{ color: "white", backgroundColor: "transparent" }}
                value={10}
              >
                Term 10
              </MenuItem>
            </Select>
          </Box>
        </MDBox>
        {/* </MDBox> */}
        <MDBox pt={3} px={3} pb={3}>
          <div style={{ width: "100%" }}>
            <Box
              gap={3}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
            >
              {currentItems.map((user, index) => (
                <IdentiKitCard key={index} user={user} term={term} />
              ))}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
              <Pagination
                size="small"
                count={Math.ceil(sortedRows.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </Box>
          </div>
        </MDBox>
      </Card>
    </Grid>
  );
}

export default IdentikitTable;
