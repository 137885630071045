import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Divider,
  CircularProgress,
  IconButton,
  Collapse,
  Tooltip,
} from "@mui/material";
import { format } from 'date-fns';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PublicationsFeed = ({ loading, data }) => {
  const [expanded, setExpanded] = useState({});
  const publicationTypeLogos = {
    "EU Tender": "/logos/EUtenders.jpeg",
    "European Commission Consultations": "/logos/EUCommission.svg",
    "European Parliament News": "/logos/EUParliament.png",
    "European Parliament Think Tank": "/logos/EUParliament.png",
    "European Commission News": "/logos/EUCommission.svg",
    "European Investment Bank - Press": "/logos/EIB.jpeg",
    "European Investment Bank - Publications":"/logos/EIB.jpeg"
    // Add more mappings as needed
  };
  const formatDate = (dateString) => format(new Date(dateString), 'yyyy-MM-dd');
  const handleExpandClick = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      {data.map((item, index) => (
        <Card
          key={index}
          sx={{
            mb: 2,
            p: 2,
            display: "flex",
            flexDirection: "column",
            width: "100% !important",
          }}
        >
          <CardContent>
          <CardContent sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {/* Logo */}
            {item.publicationType && publicationTypeLogos[item.publicationType] && (
              <Box
                component="img"
                src={publicationTypeLogos[item.publicationType]}
                alt={`${item.publicationType} logo`}
                sx={{
                  width: 100,  // Adjust the size as needed
                  height: 100, // Ensure consistency in dimensions
                  objectFit: "contain",
                }}
              />
            )}

            {/* Title and Publication Type Block */}
            <Box>
              {/* Title */}
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: "black", fontSize: "20px" }}
              >
                {item.title || "Untitled"}
              </Typography>

              {item.status && (
                <Chip label={item.status} size="small" />

              )}

            </Box>
          </CardContent>
            <Divider sx={{ width: "100%", marginBottom: "10px" }} />

            {/* Date */}
            {/* Conditional rendering based on item.date */}
            <Typography
              sx={{ fontSize: "12px", marginBottom: "5px" }}
              variant="body2"
              color="textSecondary"
            >
              {item.date ? (
                // If item.date exists, display it
                item.date
              ) : (
                // If item.date is null, check for feedbackPeriodStart and feedbackPeriodEnd
                item.feedbackPeriodStart && item.feedbackPeriodEnd ? (
                  `Feedback Period From ${formatDate(item.feedbackPeriodStart)} to ${formatDate(item.feedbackPeriodEnd)}`
                ) : (
                  // If neither item.date nor feedbackPeriodStart/feedbackPeriodEnd are available
                  null
                )
              )}
            </Typography>

            <Typography
              sx={{ fontSize: "12px", marginBottom: "5px" }}
              variant="body2"
              color="textSecondary"
            >
              {item.country && (
                // If item.date exists, display it
                item.country
              )}
            </Typography>

            {/* Summary */}
            {item.summary && (
              <Box sx={{ mt: "10px" }}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{ color: "#1A73E8", fontWeight: "500" }}
                >
                  <span
                    style={{
                      borderBottom: "1px solid #e5e5e5",
                      fontSize: "14px",
                    }}
                  >
                    Summary:
                  </span>
                </Typography>
                <Typography
                  sx={{ lineHeight: "20px", fontSize: "14px" }}
                  variant="body2"
                  paragraph
                >
                  {item.summary}
                </Typography>
              </Box>
            )}

            {/* Relevance Score */}
            <Tooltip title="Relevance score explanation" placement="bottom">
              <Typography variant="body2" sx={{ mt: 1 }}>
                Relevance Score:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {item.relevanceScore?.toFixed(2) || "N/A"}
                </span>
              </Typography>
            </Tooltip>

            {item.value && (
              <Tooltip title="Value" placement="bottom">
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Estimated Value:{" "}
                  <span style={{ fontStyle: "italic" }}>
                    {item.value.toLocaleString("en-US", { useGrouping: true }) + " " + item.currency}
                  </span>
                </Typography>
              </Tooltip>
            )}

            {/* Original Source */}
            {item.URL && (
              <Typography
                variant="body2"
                color="primary"
                sx={{ textAlign: "center" }}
              >
                <a href={item.URL} target="_blank" rel="noopener noreferrer">
                  Original Source
                </a>
              </Typography>
            )}

            {/* Expandable section for chunks */}
            {item.chunks && item.chunks.length > 0 && (
              <>
                <Box>
                  <IconButton
                    onClick={() => handleExpandClick(index)}
                    aria-expanded={expanded[index]}
                    aria-label="show more"
                  >
                    {expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                  <Typography
                    variant="body2"
                    sx={{ display: "inline-block", ml: 1 }}
                  >
                    Show Extracts
                  </Typography>
                </Box>

                <Collapse
                  in={expanded[index]}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ mt: 2 }}>
                    {item.chunks.map((chunk, chunkIndex) => {
                      // Remove "| documentID ..." from the chunk text
                      const cleanText = chunk.embeddedText.split("|")[0].trim();

                      return (
                        <Box
                          key={chunkIndex}
                          sx={{
                            mb: 3, // Increased margin for spacing between chunks
                            p: 2,  // Optional padding for clean layout
                            borderRadius: 1,
                            backgroundColor: "#f9f9f9", // Optional background color
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontStyle: "italic",
                              textAlign: "justify",
                              mb: 1,
                            }}
                          >
                            ... {cleanText} ...
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1,
                              fontWeight: "bold",
                            }}
                          >
                            Relevance Score: {chunk.relevanceScore.toFixed(2)}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Collapse>

              </>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default PublicationsFeed;
