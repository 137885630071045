import { getFreshToken } from "./authentication";

const rootAPI = process.env.ROOT_API || "https://api-636713916940.us-central1.run.app"; // Default to prod
const callAPI = async (url, method, body = null, navigate) => {
  try {
    const options = {
      method: method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const token = await getFreshToken(); // Refresh token if needed
    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      if (response.status === 401) {
        navigate("/authentication/sign-in");
        throw new Error('Token has expired. Redirecting to login...');
      } else if (response.status === 403) {
        throw new Error('Authentication failed');
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    }

    const data = await response.json();
    return data; // Return data for further handling

  } catch (error) {
    console.error(`API error: ${error.message}`);
  }
};


// Function to build URL
const buildURL = (root, path) => {
  if (!root.endsWith("/")) {
    root += "/";
  }
  if (path.startsWith("/")) {
    path = path.substring(1);
  }
  return root + path;
};

// Configuration of APIs with names and paths
const apis = (rootAPI) => ({
  welcome: buildURL(rootAPI, ""),
  showParties: buildURL(rootAPI, "parliament/parties"),
  showLLM: buildURL(
    rootAPI,
    `user/responses?queryID=c20ea61e-9d48-5699-ad83-9f402017180a&typeDocument=questions`
  ),
  showMP: buildURL(rootAPI, `MEPs/mep_info`),
  showPrompts: buildURL(rootAPI, "admin/prompts"),
  showQueries: buildURL(rootAPI, "admin/queries"),
});

export { callAPI, buildURL, apis, rootAPI };
