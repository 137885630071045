// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomStyles.css */

.select-dropdown {
    height: 1.5rem;
    width: 100%;
    color: white !important;
  }
  
  .select-dropdown .MuiOutlinedInput-notchedOutline {
    border: none;
    border-color: none !important;
  }
  
  .select-dropdown .MuiSelect-iconOutlined {
    color: none;
  }
  
  .select-dropdown.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: none;
  }
  
  .select-dropdown .MuiSvgIcon-root {
    color: white; /* Arrow color */
  }
  
  .select-dropdown .css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    color: white !important;
    border-color: none !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/customizedComponents/CustomStyles.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,cAAc;IACd,WAAW;IACX,uBAAuB;EACzB;;EAEA;IACE,YAAY;IACZ,6BAA6B;EAC/B;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,YAAY,EAAE,gBAAgB;EAChC;;EAEA;IACE,uBAAuB;IACvB,6BAA6B;EAC/B","sourcesContent":["/* CustomStyles.css */\n\n.select-dropdown {\n    height: 1.5rem;\n    width: 100%;\n    color: white !important;\n  }\n  \n  .select-dropdown .MuiOutlinedInput-notchedOutline {\n    border: none;\n    border-color: none !important;\n  }\n  \n  .select-dropdown .MuiSelect-iconOutlined {\n    color: none;\n  }\n  \n  .select-dropdown.Mui-focused .MuiOutlinedInput-notchedOutline {\n    border-color: none;\n  }\n  \n  .select-dropdown .MuiSvgIcon-root {\n    color: white; /* Arrow color */\n  }\n  \n  .select-dropdown .css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {\n    color: white !important;\n    border-color: none !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
