import { useState } from "react";
import {
  Grid,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { buildURL, rootAPI } from "api/callAPI";
import Notification from "./Notifications";
import TextInput from "./TextInput";
import { usePromptTable } from "context";
import CustomDataGrid from "./DataGrid";
import { callAPI } from "api/callAPI";
import { useNavigate } from "react-router-dom";


const PromptsManager = ({ rows, columns }) => {
  const { fetchPrompts } = usePromptTable(); // Use context
  const [selectedPromptId, setSelectedPromptId] = useState("");
  const [selectedPromptAgent, setSelectedPromptAgent] = useState("");

  const [isAddingNew, setIsAddingNew] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editPromptText, setEditPromptText] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    type: "success",
    title: "",
    message: "",
  });
  const [confirmationOpen, setConfirmationOpen] = useState(false); // State for confirmation dialog
  const [addPromptOpen, setAddPromptOpen] = useState(false); // State for confirmation dialog
  const [editPromptOpen, setEditPromptOpen] = useState(false); // State for confirmation dialog
  const navigate = useNavigate();

  const handleSavePrompt = async (promptText, promptTitle, clientTag,dateFrom,dateTo,k,kFirstRun,minRelevanceScore) => {
    console.log(promptText, promptTitle, clientTag,dateFrom,dateTo,k,kFirstRun,minRelevanceScore);
    try {
      const response = await callAPI(
        buildURL(rootAPI, "admin/add_prompt"),
        "POST",
        JSON.stringify({prompt: promptText, 
                        promptTitle: promptTitle, 
                        clientTag: clientTag,
                        dateFrom:dateFrom,
                        dateTo:dateTo,
                        k:k,
                        kFirstRun:kFirstRun,
                        minRelevanceScore:minRelevanceScore
                      }),
        navigate
      );

      // Show success notification
      setNotification({
        open: true,
        type: "success",
        title: "Prompt Saved",
        message:
          "The prompt was saved successfully: " +
          promptText.substring(0, 12) +
          " ...",
      });

      setIsAddingNew(false);
      setIsEditing(false);
      fetchPrompts();
    } catch (error) {
      // Show error notification
      console.log(error);
      setNotification({
        open: true,
        type: "error",
        title: "Error Saving Prompt",
        message: "There was an error while saving the prompt.",
      });
    }
  };

  const handleDeletePrompt = (row) => {
    setSelectedPromptId(row.promptID);
    setSelectedPromptAgent(row.promptTitle);
    setConfirmationOpen(true); // Open confirmation dialog
  };

  const deletePrompt = async () => {
    try {
      const promptID = selectedPromptId;
      const response = await callAPI(
        buildURL(rootAPI, "admin/delete_prompt"),
        "DELETE",
        JSON.stringify({ promptID: promptID}),
        navigate
      );

      // Show success notification
      setNotification({
        open: true,
        type: "success",
        title: "Prompt Deleted",
        message: "The prompt was deleted successfully.",
      });

      setConfirmationOpen(false);
      setIsAddingNew(false);
      setIsEditing(false);
      fetchPrompts();
    } catch (error) {
      // Show error notification
      setNotification({
        open: true,
        type: "error",
        title: "Error Deleting Prompt",
        message: `There was an error while deleting the prompt: ${error}`,
      });
    }
  };

  const handleEditPrompt = (prompt) => {
    setEditPromptOpen(true);
    setSelectedPromptId(prompt.promptID);
    setEditPromptText(prompt.prompt);
    setIsEditing(true);
    setIsAddingNew(false);
  };

  const handleSaveEditedPrompt = async (newPrompt, promptID) => {
    try {
      const response = await fetch(buildURL(rootAPI, "admin/edit_prompt"), {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newPrompt: newPrompt, promptID: promptID }),
      });

      // Show success notification
      setNotification({
        open: true,
        type: "success",
        title: "Prompt Edited",
        message:
          "The Prompt was saved Edited: " + newPrompt.substring(0, 12) + " ...",
      });

      setIsAddingNew(false);
      setIsEditing(false);
      fetchPrompts();
    } catch (error) {
      // Show error notification
      setNotification({
        open: true,
        type: "error",
        title: "Error Editing Prompt",
        message: "There was an error while editing the prompt.",
      });
    }
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    handleCloseEditPromptDialog();
    // setEditPromptOpen(false);
    setIsEditing(false);
    setEditPromptText("");
  };

  const handleCloseDialog = () => {
    setConfirmationOpen(false); // Close confirmation dialog
  };

  const handleCloseAddPromptDialog = () => {
    setAddPromptOpen(false); // Close confirmation dialog
  };

  const handleOpenAddPromptDialog = () => {
    setAddPromptOpen(true); // Close confirmation dialog
  };

  const handleCloseEditPromptDialog = () => {
    setEditPromptOpen(false); // Close confirmation dialog
  };

  const closeNotification = () => {
    setNotification({
      ...notification,
      open: false,
    });
  };

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <MDBox
          mx={2}
          mt={-5}
          mb={3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <MDTypography variant="h6" color="white">
              Prompts Manager
            </MDTypography>
            <Button variant="outlined" onClick={handleOpenAddPromptDialog}>
              Add New Prompt
            </Button>
          </Box>
        </MDBox>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <CustomDataGrid
            rows={rows}
            columns={columns}
            handleEdit={handleEditPrompt}
            handleDelete={handleDeletePrompt}
          />
        </Box>
      </Grid>

      {/* Dialog */}
      <Dialog open={confirmationOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this: {selectedPromptAgent}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={deletePrompt} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add New Prompt Dialog */}
      <Dialog
        //
        open={addPromptOpen}
        onClose={handleCloseAddPromptDialog}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>Add New Prompt</DialogTitle>
          <IconButton onClick={handleCloseAddPromptDialog} sx={{ mr: 1 }}>
            <ClearIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{ width: "30rem" }}>
          <TextInput
            row={1}
            rowsMax={10}
            onSave={handleSavePrompt}
            onCancel={handleCloseAddPromptDialog}
            type="Prompt"
          />
        </DialogContent>
      </Dialog>

      {/* Edit Prompt Dialog */}
      <Dialog
        //
        open={editPromptOpen}
        onClose={handleCloseEditPromptDialog}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>Edit This Prompt</DialogTitle>
          <IconButton onClick={handleCloseEditPromptDialog} sx={{ mr: 1 }}>
            <ClearIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{ width: "30rem" }}>
          {isEditing && (
            <TextInput
              row={5}
              rowsMax={10}
              onSaveEdit={handleSaveEditedPrompt}
              text={editPromptText}
              onCancel={handleCancelEdit}
              promptID={selectedPromptId}
              type="Prompt"
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Notification */}
      <Notification
        open={notification.open}
        onClose={closeNotification}
        type={notification.type}
        title={notification.title}
        message={notification.message}
      />
    </Grid>
  );
};

export default PromptsManager;
