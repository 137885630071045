import { useState, useEffect } from "react";
import { Grid, MenuItem, Select, FormControl, InputLabel, Card, Box } from "@mui/material";
import Button from '@mui/material/Button';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PromptsManager from "customizedComponents/PromptsManager";
import QueriesManager from "customizedComponents/QueriesManager";
import columnsPromptTable from "data/columnsPromptsTable";
import columnsQueries from "data/columnsQueriesTable";
import { usePromptTable } from 'context'; // Importa il contesto
import { useQueriesTable } from "context"; 

function Admin() {
  const { rowsPromptTable, fetchPrompts } = usePromptTable(); // Usa il contesto per rowsPromptTable
  const { queriesTableRows, fetchQueries } = useQueriesTable(); // Usa il contesto per queriesTableRows

  const [options, setOptions] = useState([]);
  const [selectedKey, setSelectedKey] = useState();
  const [slectedPrompt, setSelectedPrompt] = useState("");
  const [openQuery, setOpenQuery] = useState(false);

  const handleCloseOpenQuery = () => {
    setOpenQuery(false); // Close confirmation dialog
  };

  const handleOpenOpenQuery = () => {
    setOpenQuery(true); // Close confirmation dialog
  };


  useEffect(() => {
    fetchPrompts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (rowsPromptTable.length > 0) {
      const opt = rowsPromptTable.map((value) => ({
        value: value.promptID,
        label: value.promptTitle,
      })).sort((a, b) => a.label.localeCompare(b.label));

      setOptions(opt);
      if (opt.length > 0) {
        setSelectedPrompt(opt[0].value);
        setSelectedKey(opt[0].label);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPromptTable]);

  // this hook fetches queries each time slectedPrompt is changed
  useEffect(() => {
    if (slectedPrompt !== "") {
      fetchQueries(slectedPrompt);
    }
  }, [slectedPrompt]);

  // Gestione del cambiamento nel menu
  const handleChange = (event) => {
    setSelectedPrompt(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={1} mt={8}>
        <Grid item xs={20} md={4} pb={6}>
          <Card>
            <PromptsManager rows={rowsPromptTable} columns={columnsPromptTable} />
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
                  <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
              <MDTypography variant="h6" color="white">
                Queries Manager
              </MDTypography>
              <Button variant="outlined" onClick={handleOpenOpenQuery}>
              Add New Query
            </Button>
            </Box>
            </MDBox>
            <MDBox pt={3} px={5} >
              <FormControl fullWidth>
                <InputLabel id="dropdown-label">Select Prompt</InputLabel>
                <Select
                  labelId="dropdown-label"
                  label="Select Prompt"
                  value={slectedPrompt}
                  key={selectedKey}
                  onChange={handleChange}
                  multiline
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 600, // Altezza massima del menu
                        maxWidth: 500,
                      },
                    },
                  }}
                  style={{ minHeight: 50 }} // Altezza minima del select
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MDBox>
            <QueriesManager close={handleCloseOpenQuery} open={openQuery} rows={queriesTableRows} columns={columnsQueries} promptID={slectedPrompt} />
          </Card>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Admin;
