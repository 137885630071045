import React from "react";
import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const SearchInput = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    color: "white !important", // Set the input text color to white
    "& input": {
      color: "white", // Ensure the input text color is white
    },
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
    "& .MuiInputAdornment-root": {
      color: "white",
    },
  },
  "& .MuiInputLabel-root": {
    color: "white",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "white",
  },
}));

const MDSearchInput = forwardRef(({ value, onChange, ...rest }, ref) => (
  <SearchInput
    value={value}
    onChange={onChange}
    sx={{ color: "white", width: "70%" }}
    {...rest}
    ref={ref}
    variant="outlined"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Icon>search</Icon>
        </InputAdornment>
      ),
    }}
  />
));

MDSearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MDSearchInput;
