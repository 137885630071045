/**
=========================================================
* Kilner Tech Dashboard
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Kilner Tech Dashboard React base styles
import borders from "assets/theme/base/borders";

// Kilner Tech Dashboard React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

const sidenav = {
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: "nowrap",
      border: "none",
      boxShadow: "none !important",
    },

    paper: {
      width: pxToRem(250),
      backgroundColor: "transparent",
      height: `calc(100vh - ${pxToRem(32)})`,
      margin: pxToRem(16),
      borderRadius: borderRadius.lg,
      border: "none",
      boxShadow: "none !important",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default sidenav;
