import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
} from "@mui/material";
import AgentQueryDropDown from "customizedComponents/AgentQueryDropDown";
import { usePromptTable } from "context";
import { useQueriesTable } from "context";
import MDBox from "components/MDBox";
import MPInfoProfile from "customizedComponents/MPInfoProfile";

const FeedItemProfile = ({
  onQueryUpdate,
  LLMOutput,
  tabValues,
}) => {
  const [selectedPrompt, setSelectedPrompt] = useState({ value: '', prompt: '', label: '' });
  const [selectedQuery, setSelectedQuery] = useState({ value: '', label: '' });  

  const [options, setOptions] = useState([]);
  const [optionsQuery, setOptionsQuery] = useState([]);
  const { rowsPromptTable, fetchPrompts } = usePromptTable();
  const { queriesTableRows, fetchQueries } = useQueriesTable();

  useEffect(() => {
    fetchPrompts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [combinedArray, setCombinedArray] = useState([]);

  useEffect(() => {
    const combined = [
      ...(LLMOutput?.explanationsOfVote || []),
      ...(LLMOutput?.debates || []),
      ...(LLMOutput?.questions || []),
    ];

    if (combined.length > 0) {
      combined.sort((a, b) => new Date(b.date) - new Date(a.date));
      setCombinedArray(combined);
    }
  }, [LLMOutput]);

  useEffect(() => {

    if (rowsPromptTable.length > 0) {
      const opt = rowsPromptTable.map((value) => ({
        value: value.promptID,
        label: value.promptTitle,
        prompt: value.prompt
      }));
      setOptions(opt);
      if (opt.length > 0) {
        setSelectedPrompt({value:opt[0].value,
                           prompt:opt[0].prompt,
                          label:opt[0].promptTitle}
        );
      }
    }
  }, [rowsPromptTable]);

  // when a prompt is selected I fetch all the queries associted to that prompt
  useEffect(() => {
    if (selectedPrompt.value !== "") {
      console.log(selectedPrompt)
      fetchQueries(selectedPrompt.value);
    }
  }, [selectedPrompt]);

  // if new set of queries is avaialble update options, pick first query and fetch data from feed tabe with onQueryUpdate
  useEffect(() => {
    if (queriesTableRows.length > 0) {
      const opt = queriesTableRows.map((value) => ({
        value: value.queryID,
        label: value.query,
      }));
      setOptionsQuery(opt);
      if (opt.length > 0) {
        setSelectedQuery({value:opt[0].value,
                          label:opt[0].label
                        }
        );
        onQueryUpdate(opt[0].value);
      }
    }
  }, [queriesTableRows]);

  const handleChangePrompt = (event) => {
    const selectedValue = event.target.value;  // Get the selected value from the event
    const newPrompt = options.find(option => option.value === selectedValue); // Find the corresponding prompt
  
    if (newPrompt) { // Check if the selectedPrompt was found
      setSelectedPrompt({
        value: newPrompt.value,
        prompt: newPrompt.prompt,
        label: newPrompt.label
      });
    } else {
      console.error("Prompt not found");
    }
  };

  const handleChangeQuery = (event) => {
    const selectedValue = event.target.value;  // Get the selected value from the event
    const newQuery = optionsQuery.find(option => option.value === selectedValue); // Find the corresponding prompt
    if (newQuery) { // Check if the selectedPrompt was found

      console.log("New Query after selecting it form DD: ",newQuery)
      setSelectedQuery({value:newQuery.value,
                        label:newQuery.label
                      });
      onQueryUpdate(newQuery.value);
    } else {
      console.error("Query Not Found")
    }
  };

  const [openInfoPrompt,setOpenInfoPrompt] = useState(false)
  const handleOpenInfoPrompt = () => {
    setOpenInfoPrompt(true);
  };

  const handleCloseInfoPrompt = () => {
    setOpenInfoPrompt(false);
  };

  const [openInfoQuery,setOpenInfoQuery] = useState(false)
  const handleOpenInfoQuery = () => {
    setOpenInfoQuery(true);
  };

  const handleCloseInfoQuery = () => {
    setOpenInfoQuery(false);
  };
  return (
    <>
      <MDBox
        mx={2}
        mt={12}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px", // Increase the gap for spacing between dropdowns
          }}
        >
          <AgentQueryDropDown
            labelId="dropdown-prompt-label"
            title="Agent Type"
            value={selectedPrompt.value}
            onChange={handleChangePrompt}
            options={options}
            openInfo={openInfoPrompt}
            handleOpenInfo={handleOpenInfoPrompt}
            handleCloseInfo={handleCloseInfoPrompt}
            infoTitle="Agent Task"
            infoContent={selectedPrompt.prompt} // Adjust as necessary
          />
          <AgentQueryDropDown
            title="Area of Search"
            labelId="dropdown-query-label"
            value={selectedQuery.value}
            onChange={handleChangeQuery}
            options={optionsQuery}
            openInfo={openInfoQuery}
            handleOpenInfo={handleOpenInfoQuery}
            handleCloseInfo={handleCloseInfoQuery}
            infoTitle="Query"
            infoContent={selectedQuery.label}
          />
        </Box>
      </MDBox>

      <Box sx={{ width: "100%" }}>
        {LLMOutput ? (
          <MPInfoProfile tabValues={tabValues} interventions={combinedArray} />
        ) : (
          <Card>
            <CardContent sx={{ textAlign: "center", pt: 3 }}>
              No Data Found
            </CardContent>
          </Card>
        )}
      </Box>
    </>
  );
};

export default FeedItemProfile;
