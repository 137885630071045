import PropTypes from "prop-types";
// @mui
import Box from "@mui/material/Box";
// components
import { useMessagesScroll } from "./hooks";
import ChatMessageItem from "./chat-message-item";

// ----------------------------------------------------------------------

export default function ChatMessageList({
  messages = [],
  participants,
  newmessages,
}) {
  const { messagesEndRef } = useMessagesScroll(messages);

  return (
    <>
      <Box
        sx={{
          maxHeight: "calc(100vh - 64px)",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {messages.map((message) => (
          <ChatMessageItem
            key={message.id}
            message={message}
            participants={participants}
            newmessages={newmessages}
          />
        ))}
        {/* Ensure the scrollbar is scrolled to the last message */}
        <div ref={messagesEndRef} />
      </Box>

      {/* Your lightbox or other components */}
    </>
  );
}

ChatMessageList.propTypes = {
  messages: PropTypes.array,
  participants: PropTypes.array,
  newmessages: PropTypes.string,
};
