import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { ClearableTextField } from "./ClearableTextField";

const TextInput = ({
  onSave,
  onSaveEdit,
  onCancel,
  disabled,
  text,
  promptID,
  queryID,
  type,
  row,
  rowsMax,
}) => {
  const [inputText, setInputText] = useState(text);
  const [agentTitle, setAgentTitle] = useState("");

  const handleClear = () => {
    setInputText("");
    setAgentTitle(""); // Clear agent title as well
  };

  const handleSave = () => {
    if (onSave) {
      if (type === "Query") {
        onSave(inputText, promptID);
      } else if (type === "Prompt") {
        onSave(inputText, agentTitle);
      }
    } else if (onSaveEdit) {
      if (type === "Query") {
        onSaveEdit(inputText, queryID);
      } else if (type === "Prompt") {
        onSaveEdit(inputText, promptID);
      }
    }
    setInputText("");
    setAgentTitle(""); // Clear agent title after saving
    onCancel();
  };

  const handleCancel = () => {
    onCancel();
    setInputText("");
    setAgentTitle(""); // Clear agent title on cancel
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      my={2}
    >
      {type === "Prompt" && onSave && (
        <ClearableTextField
          label="Agent Title"
          variant="outlined"
          fullWidth
          value={agentTitle}
          onChange={(e) => setAgentTitle(e.target.value)}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} disabled={disabled}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <TextField
        label={`Add new ${type}`}
        variant="outlined"
        multiline
        // row={1} // Minimum number of rows (1 line)
        // rowsMax={rowsMax}
        maxRows={5}
        fullWidth
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} disabled={disabled}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Box display="flex" justifyContent="flex-end" gap={2} width="100%">
        <Button
          variant="outlined"
          sx={{
            color: "black !important", // Set the text color to black
            borderColor: "black !important", // Set the border color to black
            "&:hover": {
              borderColor: "black", // Ensure the border remains black on hover
              color: "black", // Ensure the text remains black on hover
            },
          }}
          onClick={handleCancel}
          disabled={disabled}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={disabled}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default TextInput;
