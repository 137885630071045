import { buildURL, rootAPI, callAPI } from "api/callAPI";
import React, { useState, useEffect, useRef } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Box, Pagination } from "@mui/material";
import PromptsQuerySelectors from "customizedComponents/PromptQuerySelectors";
import FeedItem from "./FeedItem";
import { useNavigate } from "react-router-dom";

const Feed = () => {
  const [loading, setLoading] = useState(false);
  const cache = useRef({});
  const [feedData, setFeedData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [filteredDataState, setFilteredDataState] = useState([]); // New state for filtered data
  const [sortCriteria, setSortCriteria] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterMP, setFilterMP] = useState([]);
  const [filterCountry,setFilterCountry] = useState([]);
  const [filterParty, setFilterParty] = useState([]);
  const [filterGroup, setFilterGroup] = useState([]);
  const [filterTypeDocument, setFilterTypeDocument] = useState([]);
  const [filterTerm, setFilterTerm] = useState("");
  const [filterTermValue, setFilterTermValue] = useState("");
  const [filterConcernRange, setFilterConcernRange] = useState([0, 100]);
  const [filterRelevanceScore,setFilterRelevanceScore] = useState([0,1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [availableParties, setAvailableParties] = useState([]);
  const [availableMPs, setAvailableMPs] = useState([]);
  const [availableCountries,setAvailableCountries] = useState([])
  const [availableGroups, setAvailableGroups] = useState([]);
  const itemsPerPage = 10;
  const navigate = useNavigate(); // Access the navigate function here

  const fetchFeed = async (queryID) => {
    setLoading(true);
    let feedData;
    if (!cache.current[queryID]) {
      try {
        const data = await callAPI(
          buildURL(rootAPI, `user/feed?queryID=${queryID}`),
          "GET",
          null,
          navigate
        );
        feedData = await data.response;
        cache.current[queryID] = feedData;
      } catch (error) {
        console.error("Error fetching data:", error);
        feedData = []; // Provide a default value in case of an error
      } finally {
        setLoading(false);
      }
    } else {
      feedData = cache.current[queryID];
    }
    setFeedData(feedData);

    // Extract unique and sorted values for parties, MPs, and groups
    const parties = [...new Set(feedData.map((item) => item.party))]
      .filter((party) => party)
      .sort();
    const MPs = [...new Set(feedData.map((item) => item.name))]
      .filter((name) => name)
      .sort();
    const groups = [...new Set(feedData.map((item) => item.group))]
      .filter((group) => group)
      .sort();
    
    const countries = [...new Set(feedData.map((item) => item.country))]
    .filter((country) => country)
    .sort();
    setAvailableParties(parties);
    setAvailableMPs(MPs);
    setAvailableGroups(groups);
    setAvailableCountries(countries);
    setLoading(false);
  };

  useEffect(() => {

    let data = [...feedData];
    if (data.length > 0) {
      if (filterStartDate || filterEndDate) {
        data = data.filter((item) => {
          const itemDate = new Date(item.date || "");
      
          // Check if filterStartDate is set and if itemDate is after or equal to filterStartDate
          const isAfterStartDate = filterStartDate ? itemDate >= new Date(filterStartDate) : true;
      
          // Check if filterEndDate is set and if itemDate is before or equal to filterEndDate
          const isBeforeEndDate = filterEndDate ? itemDate <= new Date(filterEndDate) : true;
      
          // Return true if either condition is met
          return isAfterStartDate && isBeforeEndDate;
        });
      }
      // Filter by selected MPs
      
      if (filterMP && filterMP.length > 0) {
        data = data.filter((item) =>
          filterMP.some((mp) => (item.name || "").toLowerCase().includes(mp.toLowerCase()))
        );
      }

      if (filterCountry && filterCountry.length > 0) {
        data = data.filter((item) =>
          filterCountry.some((country) =>
            (item.country || "").toLowerCase().includes(country.toLowerCase())
          )
        );
      }

      // Filter by selected Parties
      if (filterParty && filterParty.length > 0) {
        data = data.filter((item) =>
          filterParty.some((party) => (item.party || "").toLowerCase().includes(party.toLowerCase()))
        );
      }

      // Filter by selected Groups
      if (filterGroup && filterGroup.length > 0) {
        data = data.filter((item) =>
          filterGroup.some((group) => (item.group || "").toLowerCase().includes(group.toLowerCase()))
        );
      }

      // Filter by selected Document Types
      if (filterTypeDocument && filterTypeDocument.length > 0) {
        data = data.filter((item) =>
          filterTypeDocument.some((type) =>
            (item.typeDocument || "").toLowerCase().includes(type.toLowerCase())
          )
        );
      }

      if (filterTerm) {
        const currentTerm = 10; // to modify for next term
        const filterSitting = (item) => {

          if (filterTerm === 'Past MEPs'){
            return !item.termsActivity.includes(currentTerm);
          }
          else if (filterTerm === 'Currently sitting MEPs'){
            return item.termsActivity.includes(currentTerm);
          }
          return true
        }
        data = data.filter(filterSitting);
      }

      if (filterConcernRange) {
        data = data.filter(
          (item) =>
            (item.levelOfConcern || 0) >= filterConcernRange[0] &&
            (item.levelOfConcern || 0) <= filterConcernRange[1]
        );
      }

      if (filterRelevanceScore) {
        data = data.filter(
          (item) =>
            (item.relevanceScore || 1) >= filterRelevanceScore[0] &&
            (item.relevanceScore || 0) <= filterRelevanceScore[1]
        );
      }
      // Sorting logic
      switch (sortCriteria) {
        case "date":
          data.sort((a, b) =>
            sortOrder === "asc"
              ? new Date(a.date || "") - new Date(b.date || "")
              : new Date(b.date || "") - new Date(a.date || "")
          );
          break;
        case "levelOfConcern":
          data.sort((a, b) =>
            sortOrder === "asc"
              ? (a.levelOfConcern || 0) - (b.levelOfConcern || 0)
              : (b.levelOfConcern || 0) - (a.levelOfConcern || 0)
          );
          break;
        case "relevanceScore":
          data.sort((a, b) =>
            sortOrder === "asc"
              ? (a.relevanceScore || 0) - (b.relevanceScore || 0)
              : (b.relevanceScore || 0) - (a.relevanceScore || 0)
          );
          break;
        default:
          break;
          
      }

      // Update the filtered data state
      setFilteredDataState(data);

      // Pagination logic
      const startIndex = (currentPage - 1) * itemsPerPage;
      setDisplayedData(data.slice(startIndex, startIndex + itemsPerPage));
    }
  }, [
    feedData,
    sortCriteria,
    sortOrder,
    filterStartDate,
    filterEndDate,
    filterCountry,
    filterMP,
    filterParty,
    filterGroup,
    filterTypeDocument,
    filterConcernRange,
    currentPage,
    filterTerm,
    filterTermValue,
    filterRelevanceScore
  ]);

  const handleSort = (criteria) => {
    if (sortCriteria === criteria) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortCriteria(criteria);
      setSortOrder("asc");
    }
  };

  const handleConcernChange = (event, newValue) => {
    setFilterConcernRange(newValue);
  };
  const handleRelevanceChange = (event, newValue) => {
    setFilterRelevanceScore(newValue);
  };

  const handleResetClick = () => {
    setFilterConcernRange([0, 100]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PromptsQuerySelectors
        onQueryUpdate={fetchFeed}
        filterStartDate={filterStartDate}
        setFilterStartDate={setFilterStartDate}
        filterEndDate={filterEndDate}
        setFilterEndDate={setFilterEndDate}
        availableMPs={availableMPs}
        filterMP={filterMP}
        setFilterMP={setFilterMP}
        availableCountries={availableCountries}
        filterCountry={filterCountry}
        setFilterCountry={setFilterCountry}
        availableParties={availableParties}
        filterParty={filterParty}
        setFilterParty={setFilterParty}
        availableGroups={availableGroups}
        filterGroup={filterGroup}
        setFilterGroup={setFilterGroup}
        filterTypeDocument={filterTypeDocument}
        setFilterTypeDocument={setFilterTypeDocument}
        filterTerm={filterTerm}
        setFilterTerm={setFilterTerm}
        filterTermValue={filterTermValue}
        setFilterTermValue={setFilterTermValue}
        filterConcernRange={filterConcernRange}
        handleConcernChange={handleConcernChange}
        filterRelevanceScore={filterRelevanceScore}
        handleRelevanceChange={handleRelevanceChange}
        handleResetClick={handleResetClick}
        handleSort={handleSort}
        sortCriteria={sortCriteria}
        sortOrder={sortOrder}
      />
      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        {/* Feed Data */}
        <FeedItem
          loading={loading}
          data={displayedData}
          filterMP={filterMP}
          setFilterMP={setFilterMP}
          setCurrentPage={setCurrentPage}
        />

        {/* Pagination */}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination
            count={Math.ceil(filteredDataState.length / itemsPerPage)} // Use filteredDataState for pagination
            page={currentPage}
            onChange={(e, value) => setCurrentPage(value)}
          />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Feed;
