import { useCallback, useEffect, useRef } from "react";

// ----------------------------------------------------------------------

export default function useMessagesScroll(messages) {
  const messagesEndRef = useRef(null);

  const scrollMessagesToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    scrollMessagesToBottom();
  }, [scrollMessagesToBottom]);

  return {
    messagesEndRef,
  };
}
