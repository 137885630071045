import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import { LoadingButton } from "@mui/lab";
// import LoadingButton from '@mui/lab/LoadingButton';

import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
// hooks
import { useResponsive } from "../../../src/hooks/use-responsive";

import Iconify from "../../../src/components/Iconify";
//
import { useCollapseNav } from "./hooks";
import ChatNavItem from "./chat-nav-item";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { rootAPI, buildURL, callAPI } from "api/callAPI";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "components/LoadingButton";
import { useNavigate } from "react-router-dom";
// import ChatNavSearchResults from "./chat-nav-search-results";

// ----------------------------------------------------------------------

const NAV_WIDTH = 320;

const NAV_COLLAPSE_WIDTH = 20;

export default function ChatNav({

  selectedConversationId,
  onSelectConversation,

}) {
  const theme = useTheme();
  const navigate = useNavigate()
  const mdUp = useResponsive("up", "md");
  const [addPromptOpen, setAddPromptOpen] = useState(true);
  const [Title, setTitle] = useState("");
  const [searchAreas, setsearchAreas] = useState("");
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null); // Track which chat is being deleted
  const [chatTitleToDelete, setChatTitleToDelete] = useState(null); // Track which chat is being deleted
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [searchAreasList, setsearchAreasList] = useState([]);
  const handleCloseAddPromptDialog = () => {
    setAddPromptOpen(false);
  };
  const handleOpenAddPromptDialog = () => {
    setAddPromptOpen(true);
  };

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleSend = async () => {
    setLoading(true); // Start loading spinner
    try {
      setbuttonDisable(true);
      const body_request = JSON.stringify({
        chat_title: Title,
        search_area: searchAreas,
      });
      const response = await callAPI(
        buildURL(rootAPI, "chat/new_chat"),
        "POST",
        body_request,
        navigate
      );

      // Check if the response is OK before closing the dialog
      if (!response) {
        throw new Error(`HTTP error! No new chat created`);
      }

      // Call fetchChatData before updating the state
      await fetchChatData();
      // Reset state and close the dialog after fetching the chat data
      setTitle("");
      handleCloseAddPromptDialog();
      const id = response.response.split(" ")[0];
      onSelectConversation(id);
    } catch (error) {
      console.error("error: ", error);
    } finally {
      setLoading(false); // Stop loading spinner
      setbuttonDisable(false);
    }
  };

  const {
    collapseDesktop,
    onCloseDesktop,
    onCollapseDesktop,
    //
    openMobile,
    onOpenMobile,
    onCloseMobile,
  } = useCollapseNav();

  useEffect(() => {
    if (!mdUp) {
      onCloseDesktop();
    }
  }, [onCloseDesktop, mdUp]);

  const handleToggleNav = useCallback(() => {
    if (mdUp) {
      onCollapseDesktop();
    } else {
      onCloseMobile();
    }
  }, [mdUp, onCloseMobile, onCollapseDesktop]);

  // Fetching Chat Data
  const fetchChatData = async () => {
    try {
      const response = await callAPI(
        buildURL(rootAPI, "chat/show_chats"),
        "GET",
        null,
        navigate
      );

      // Extract a`nd validate the response
      const chatData = Array.isArray(response.response)
        ? response.response
        : [];
      setChatList(chatData);
      // Cache the response
    } catch (error) {
      console.error("Error fetching chat data:", error);
    }
  };

  // Fetching Chat Data
  const fetchSearchAreas = async () => {
    try {
      const response = await callAPI(
        buildURL(rootAPI, "chat/search_areas"),
        "GET",
        null,
        navigate
      );

      const SearchAreasList = Array.isArray(response.response)
        ? response.response
        : [];
      setsearchAreasList(SearchAreasList);
    } catch (error) {
      console.error("Error fetching chat data:", error);
    }
  };

  // Effect to Fetch Data on Mount
  useEffect(() => {
    fetchChatData();
    fetchSearchAreas();
  }, []);

  const handleChatDelete = (chatID,chatTitle) => {
    setChatToDelete(chatID);
    setChatTitleToDelete(chatTitle);
    setConfirmDialogOpen(true);
  };
  const deleteChat = async () => {
    if (!chatToDelete) return;
    setbuttonDisable(true);
    setLoading(true);
    try {

      const body_request = JSON.stringify({chat_id: chatToDelete,});
      const response = await callAPI(
        buildURL(rootAPI, "chat/delete_chat"),
        "POST",
        body_request,
        navigate
      );
      fetchChatData();
      if (selectedConversationId == chatToDelete) onSelectConversation(null);
      if (!response) {
        throw new Error(`HTTP error! Failed to delete chat!`);
      }
    } catch (error) {
      console.error("error: ", error);
    } finally {
      setConfirmDialogOpen(false); // Close the confirmation dialog
      setChatToDelete(null); // Reset the chat ID
      setbuttonDisable(false);
      setLoading(false);
    }
  };

  const renderToggleBtn = (
    <IconButton
      onClick={onOpenMobile}
      sx={{
        left: 0,
        top: 84,
        zIndex: 9,
        width: 32,
        height: 32,
        position: "absolute",
        borderRadius: `0 12px 12px 0`,
        bgcolor: theme.palette.primary.main,
        // boxShadow: theme.customShadows.primary,
        color: theme.palette.primary.contrastText,
        "&:hover": {
          bgcolor: theme.palette.primary.dark,
        },
      }}
    >
      <Iconify width={16} icon="hugeicons:greater-than" />
    </IconButton>
  );

  const renderContent = (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ p: 2.5, pb: 0 }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!collapseDesktop && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Chat History</Typography>
              <IconButton onClick={handleOpenAddPromptDialog}>
                <Box
                  sx={{
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  <Iconify icon="eva:message-square-outline" />
                  New
                </Box>
              </IconButton>
            </Box>
          )}
          <IconButton onClick={handleToggleNav}>
            <Iconify
              sx={collapseDesktop ? { position: "absolute", left: -20 } : {}}
              icon={
                collapseDesktop
                  ? "eva:arrow-ios-forward-fill"
                  : "eva:arrow-ios-back-fill"
              }
            />
          </IconButton>
        </Box>
      </Stack>
      <Divider />
  
      <Box sx={{ overflowY: "auto", overflowX: "hidden" }}>
        {chatList.map((chat, index) => (
          <Box
            key={chat.chatID} // Add a key prop to the Box
            sx={{
              bgcolor: index % 2 === 0 ? "background.default" : "action.hover", // Alternate colors
              cursor: "pointer", // Change cursor to pointer
              transition: "background-color 0.3s", // Smooth transition for hover effect
              '&:hover': {
                bgcolor: "action.selected", // Change background on hover
                // boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Add shadow on hover
              },
            }}
          >
              <ChatNavItem
                selected={selectedConversationId === chat.chatID}
                chatID={chat.chatID}
                chatTitle={chat.chatTitle}
                conversation={chat}
                collapse={collapseDesktop}
                onCloseMobile={onCloseMobile}
                fetchLLMResponses={fetchChatData}
                onSelectConversation={onSelectConversation}
                onChatDelete={handleChatDelete}
              />
          </Box>
        ))}
      </Box>
    </>
  );

  return (
    <>
      {!mdUp && renderToggleBtn}

      {mdUp ? (
        <Stack
          sx={{
            height: 1,
            flexShrink: 0,
            width: NAV_WIDTH,
            borderRight: `solid 1px ${theme.palette.divider}`,
            transition: theme.transitions.create(["width"], {
              duration: theme.transitions.duration.shorter,
            }),
            ...(collapseDesktop && {
              width: NAV_COLLAPSE_WIDTH,
            }),
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openMobile}
          onClose={onCloseMobile}
          slotProps={{
            backdrop: { invisible: true },
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "#fff",
              margin: 0,
              borderRadius: 0,
              height: "100vh",
              width: 350,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      <Dialog open={addPromptOpen} onClose={handleCloseAddPromptDialog}>
        <Box sx={{ width: { xs: "300px", md: "500px" } }}>
          <DialogTitle
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            New Chat
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseAddPromptDialog}
              // sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: "100%", my: 1 }}>
              <Autocomplete
                options={searchAreasList}
                getOptionLabel={(option) => option}
                value={searchAreas}
                onChange={(e, newValue) => setsearchAreas(newValue || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Area"
                    variant="outlined"
                  />
                )}
                sx={{ mb: 2 }}
              />

              <TextField
                label="Title"
                type="text"
                value={Title}
                onChange={handleChangeTitle}
                // InputLabelProps={{ shrink: true }}
                sx={{ width: "100%", mb: 2 }}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ my: 2 }}>
            <Button
              onClick={handleCloseAddPromptDialog}
              variant="outlined"
              sx={{
                color: "black !important", // Set the text color to black
                borderColor: "black !important", // Set the border color to black
                "&:hover": {
                  borderColor: "black", // Ensure the border remains black on hover
                  color: "black", // Ensure the text remains black on hover
                },
                marginRight: 1,
              }}
            >
              Cancel
            </Button>

            <LoadingButton
              disabled={buttonDisable}
              variant="contained"
              onClick={handleSend}
              color="primary"
              loading={loading}
            >
              Create
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={() => {
          setConfirmDialogOpen(false);
        }}
      >
        <Box sx={{ width: { xs: "300px", md: "500px" } }}>
          <DialogTitle
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Are you sure?
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setConfirmDialogOpen(false);
              }}
              // sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: "100%", my: 1 }}>
              <Typography>
                Are you sure you want to delete <b>{chatTitleToDelete}</b>?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ my: 2 }}>
            <Button
              onClick={() => {
                setConfirmDialogOpen(false);
              }}
              variant="outlined"
              sx={{
                color: "black !important",
                borderColor: "black !important",
                "&:hover": {
                  borderColor: "black",
                  color: "black",
                },
                marginRight: 1,
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={buttonDisable}
              variant="contained"
              onClick={deleteChat}
              color="primary"
              loading={loading}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

ChatNav.propTypes = {
  contacts: PropTypes.array,
  conversations: PropTypes.object,
  loading: PropTypes.bool,
  selectedConversationId: PropTypes.string,
  onSelectConversation: PropTypes.func.isRequired, // Add required prop type
  fetchLLMResponses: PropTypes.func.isRequired,
  cache: PropTypes.func,
};
