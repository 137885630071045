import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ClearableTextField } from "./ClearableTextField";

const TextInput = ({
  onSave,
  onSaveEdit,
  onCancel,
  disabled,
  text,
  promptID,
  queryID,
  type
}) => {
  const [inputText, setInputText] = useState(text);
  const [agentTitle, setAgentTitle] = useState("");
  const [clientTag, setClientTag] = useState("");
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [dateFrom,setDateFrom] = useState(null);
  const [dateTo,setDateTo] = useState(null);
  const [k,setK] = useState(300);
  const [kFirstRun,setKFirstRun] = useState(8000);
  const [minRelevanceScore,setMinRelevanceScore] = useState(0.33);

  const handleClear = () => {
    setInputText("");
    setAgentTitle(""); // Clear agent title as well
    setClientTag(""); // Clear agent title as well

  };

  const handleSave = () => {
    if (onSave) {
      if (type === "Query") {
        onSave(inputText, promptID);
      } else if (type === "Prompt") {
        onSave(inputText, agentTitle,clientTag,dateFrom,dateTo,k,kFirstRun,minRelevanceScore);
      }
    } else if (onSaveEdit) {
      if (type === "Query") {
        onSaveEdit(inputText, queryID);
      } else if (type === "Prompt") {
        onSaveEdit(inputText, promptID);
      }
    }
    handleClear();
    onCancel();
  };

  const handleCancel = () => {
    onCancel();
    handleClear();

  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      my={2}
    >
      <TextField
        label={`Add new ${type}`}
        variant="outlined"
        multiline
        maxRows={5}
        fullWidth
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} disabled={disabled}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
          {type === "Prompt" && onSave && (
      <>
        <ClearableTextField
          label="Agent Title"
          variant="outlined"
          fullWidth
          value={agentTitle}
          onChange={(e) => setAgentTitle(e.target.value)}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} disabled={disabled}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <ClearableTextField
          label="Client Tag"
          variant="outlined"
          fullWidth
          value={clientTag}
          onChange={(e) => setClientTag(e.target.value)}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} disabled={disabled}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          startIcon={showAdvancedOptions ? <RemoveIcon /> : <AddIcon />}
          onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
          variant="outlined"
          fullWidth
          sx={{
            color: "black !important",
            borderColor: "black !important",
            "&:hover": {
              borderColor: "black",
              color: "black",
            },
          }}
        >
          {showAdvancedOptions ? "Hide Advanced Options" : "Show Advanced Options"}
        </Button>
        
        {showAdvancedOptions && (
          <>
            <Box display="flex" gap={2} width="100%">
              <TextField
                label="Date From"
                type="date"
                fullWidth
                value={dateFrom || ''}
                onChange={(e) => {
                  setDateFrom(e.target.value);
                  console.log('Date From:', e.target.value);
                }}
                disabled={disabled}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Date To"
                type="date"
                fullWidth
                value={dateTo || ''}
                onChange={(e) => {
                  setDateTo(e.target.value);
                  console.log('Date To:', e.target.value);
                }}
                disabled={disabled}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box display="flex" gap={2} width="100%">
              <TextField
                label="Min Rel. Score"
                type="number"
                fullWidth
                value={minRelevanceScore}
                onChange={(e) => {
                  const value = Math.min(Math.max(0, parseFloat(e.target.value)), 1);
                  setMinRelevanceScore(value);
                }}
                disabled={disabled}
                inputProps={{ min: 0, max: 1, step: 0.01 }}
              />
              <TextField
                label="K Value"
                type="number"
                fullWidth
                value={k}
                onChange={(e) => {
                  const value = Math.max(1, parseInt(e.target.value));
                  setK(value);
                }}
                disabled={disabled}
                inputProps={{ min: 1 }}
              />
              <TextField
                label="K First Run"
                type="number"
                fullWidth
                value={kFirstRun}
                onChange={(e) => {
                  const value = Math.max(1, parseInt(e.target.value));
                  setKFirstRun(value);
                }}
                disabled={disabled}
                inputProps={{ min: 1 }}
              />
            </Box>
          </>
        )}
      </>
    )}

      <Box display="flex" justifyContent="flex-end" gap={2} width="100%">
        <Button
          variant="outlined"
          sx={{
            color: "black !important",
            borderColor: "black !important",
            "&:hover": {
              borderColor: "black",
              color: "black",
            },
          }}
          onClick={handleCancel}
          disabled={disabled}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={disabled}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default TextInput;
