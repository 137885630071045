import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  position: "relative",
}));

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -12,
  marginLeft: -12,
}));

export default function LoadingButton({ loading, children, ...props }) {
  return (
    <StyledButton {...props} disabled={loading || props.disabled}>
      {children}
      {loading && <LoadingSpinner size={24} />}
    </StyledButton>
  );
}
